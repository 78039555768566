.container {
  width: 50%;
}
.inputClassName {
  background-color: transparent;
  box-shadow: inset 2.5px 2.5px 2.5px rgb(238, 238, 238),
    inset -2.5px -2.5px 2.5px rgb(252, 252, 252);
  border-radius: 0.5em;
  border: 2px solid transparent;
  border-bottom-color: rgb(255, 255, 255);
  border-right-color: rgb(255, 255, 255);
  border-top-color: rgb(235, 235, 235);
  border-left-color: rgb(235, 235, 235);
  transition: all 250ms;
}
/* .inputClassName:hover {
  border: 2px solid var(--colorB);
} */

@media screen and (max-width: 860px) {
  .container {
    width: 100%;
  }
}
