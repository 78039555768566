.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1em;
  width: 100%;
}
.searchInput {
  min-width: 100%;
  padding: 0.8em;
  border: 1px solid var(--lightDark);
  width: 26em;
  border-radius: 0.25em;
}
.searchInput:focus {
  outline: 1px solid var(--colorA);
}
.searchItemWarper {
  display: flex;
  flex-direction: column;
  width: 100%;
}
