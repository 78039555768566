.container {
  width: 100%;
  display: flex;
  flex-direction: row;
  /* flex-wrap: wrap; */
  align-items: center;
  gap: 1em;
  justify-content: space-between;
  background-color: white;
  border-radius: 1em;
  padding: 2em;
  filter: drop-shadow(0px 0px 5px var(--lightDark));
}
.statusContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  /* flex-wrap: wrap; */
  align-items: center;
  gap: 3.5em;
  justify-content: center;
  background-color: white;
  border-radius: 1em;
  padding: 2em;
  filter: drop-shadow(0px 0px 5px var(--lightDark));
}
