.MenuTitle {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 0.5em;
  white-space: nowrap;
  cursor: pointer;
}
.MenuTitleText > * {
  cursor: pointer;
  font-weight: 600;
  text-transform: capitalize;
}
.dropdown {
  position: relative;
  display: inline-block;
  padding: 0.5em;
}

.dropdown_content_left,
.dropdown_content_right {
  margin-top: 1em;
  z-index: 5;
  border-radius: 0.5em;
  box-shadow: 0px 0px 5px rgb(200, 200, 200);
  color: black;
  background-color: white;
  padding: 0.5em 0em;
  display: flex;
  flex-direction: column;
  /* display: none; */
  transform: scale(0);
  transform-origin: 0 0;
  transition: ease-in-out 300ms;
  position: absolute;
  /* right: 0px; */
}
.dropdown_content_left *,
.dropdown_content_right * {
  cursor: pointer;
}
.dropdown_content_left {
  left: 0;
}
.dropdown_content_right {
  right: 0;
}
.dropdown_content_left::before,
.dropdown_content_right::before {
  content: "";
  top: 0px;
  border: 0.8em solid transparent;
  border-bottom-color: white;
  transform: translateY(-100%);
  position: absolute;
}
.dropdown_content_left::before {
  left: 10%;
}
.dropdown_content_right::before {
  right: 10%;
}
.dropdown:hover .menuIcon {
  transition: ease-in-out 100ms;
  transform: rotate(180deg);
}
.dropdown:hover .dropdown_content_left,
.dropdown:hover .dropdown_content_right {
  display: flex;
  transform: scale(1);
  transition: ease-in-out 300ms;
  /* min-height: 10em; */
}
