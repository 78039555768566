.container {
  border-radius: 0.5em;
  background-color: #f2f3f5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 11em;
  width: 11em;
  margin: 1em;
  padding: 1em 1em 0em 1em;
  transition: ease-in-out 300ms;
  cursor: pointer;
}
.image {
  width: 100%;
  height: auto;
}
.name {
  color: var(--colorC);
  text-transform: capitalize;
  transition: ease-in-out 300ms;
  text-align: center;
  cursor: pointer;
}
.count {
  visibility: hidden;
  color: var(--colorA);
  text-transform: uppercase;
  font-size: 0.8em;
  transition: ease-in-out 300ms;
  cursor: pointer;
}
.container:hover {
  transform: scale(1.05);
  box-shadow: 0px 0px 5px #bfc7d4;
}
.container:hover .name {
  color: var(--colorA);
}
.container:hover .count {
  visibility: visible;
}

@media screen and (max-width: 860px) {
  .container {
    height: 7em;
    width: 7em;
    margin: 1em;
    padding: 1em 1em 0em 1em;
  }
  .name {
    font-size: 0.7em;
  }
  .count {
    font-size: 0.5em;
  }
}
