.searchItem {
  border: 1px solid var(--lightDark);
  border-radius: 0.5em;
  margin: 0.5em 0em;
  width: 100%;
  height: 3em;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  box-shadow: 0px 0px 2px var(--lightDark);
  cursor: pointer;
}
.searchItem * {
  cursor: pointer;
}
.searchItemImageSection {
  min-height: 100%;
  width: 5em;
  background-color: var(--colorA);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.searchItemContent {
  padding: 0.5em;
  text-transform: capitalize;
}
