.container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  gap: 1em;
  overflow: hidden;
}
.header {
  background-color: var(--colorA);
  border-radius: 0.25em;
  padding: 0.5em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-transform: uppercase;
  width: 100%;
}
.infoWarper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 1em;
}
.stage {
  height: 2em;
  width: 2em;
  text-align: center;
  padding: 0.5em;
  background-color: white;
  font-size: 0.8em;
  border-radius: 0.5em;
  font-weight: bold;
  color: var(--colorA);
}
.title {
  color: white;
}
.ChildrenWarper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
  transition: ease-in-out 1s;
}
.ChildrenWarperAnimate {
  transition: ease-in-out 1s;
  height: 0em;
}
