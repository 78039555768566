.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: white;
  border-top: 1px solid var(--colorA);
  padding: 1em;
}
.container span {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.socialMediaWarper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
}
.Icons {
  cursor: pointer;
  z-index: 1;
}
.Icons * {
  cursor: pointer;
}
.IconsWarper {
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 45px;
  max-width: 40px;
  padding: 0.5em;
  transition: ease-in-out 250ms;
}
.IconsWarper::after {
  content: "";
  position: absolute;
  background-color: rgba(255, 255, 255);
  padding: 0% 0% 0 0;
  opacity: 0;
  border-radius: 50%;
  z-index: 0;
  transition: ease-in-out 250ms;
}
.IconsWarper:hover::after,
.IconsWarper:active::after {
  background-color: white;
  opacity: 1;
  padding: 100% 100% 0 0;
}
.IconsWarper:active::after {
  background-color: white;
  opacity: 1;
  padding: 80% 80% 0 0;
}

.IconsWarper .Icons {
  transition: all 250ms;
}
.IconsWarper:nth-child(1):hover .Icons,
.IconsWarper:nth-child(1):active .Icons {
  fill: #1877f2;
}
.IconsWarper:nth-child(2):hover .Icons,
.IconsWarper:nth-child(2):active .Icons {
  fill: #1da1f2;
}
.IconsWarper:nth-child(3):hover .Icons,
.IconsWarper:nth-child(3):active .Icons {
  fill: #ff0000;
}
.IconsWarper:nth-child(4):hover .Icons,
.IconsWarper:nth-child(4):active .Icons {
  fill: #e1306c;
}
.IconsWarper:nth-child(5):hover .Icons,
.IconsWarper:nth-child(5):active .Icons {
  fill: #0072b1;
}

@media screen and (max-width: 860px) {
  .container {
    flex-direction: column;
    justify-content: space-evenly;
    padding: 0.5em;
  }
  .container span {
    font-size: 0.8em;
  }
}
