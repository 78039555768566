.conrtainer {
  height: 21em;
  width: 18em;
  background-color: #f2f3f5;
  border-radius: 2em;
  display: flex;
  flex-direction: column;
  padding: 1em;
  gap: 1em;
}

.DetailsSection,
.ImageSection {
  height: 50%;
  width: 100%;
  background-color: white;
  border-radius: 2em;
}
.ImageSection {
  overflow: hidden;
}
.ImageSection img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.DetailsSection {
  color: var(--colorAA);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1em;
}
.DetailsSection .title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

/* Card Two */
.conrtainerTwo {
  height: 21em;
  width: 18em;
  background-color: #f2f3f5;
  border-radius: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.DetailsSectionTwo,
.ImageSectionTwo {
  height: 50%;
  width: 100%;
  background-color: white;
  border-radius: 2em;
}
.ImageSectionTwo {
  overflow: hidden;
}
.ImageSectionTwo img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.DetailsSectionTwo {
  color: var(--colorAA);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1em;
  width: calc(100% - 1em);
  margin: 0.5em;
}
/* Card Three */
.conrtainerThree {
  height: 21em;
  width: 18em;
  border-radius: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.conrtainerThree::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 60%;
  background-color: #f2f3f5;
  bottom: 0px;
  border-radius: 1em;
  z-index: -1;
}

.DetailsSectionThree,
.ImageSectionThree {
  height: 50%;
  width: 100%;
  z-index: 0;
}
.ImageSectionThree {
  overflow: hidden;
  border-radius: 2em;
  width: 85%;
}
.ImageSectionThree img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.DetailsSectionThree {
  color: var(--colorAA);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1em;
  width: 100%;
}

/* Card Four */

.conrtainerFour {
  height: 21em;
  width: 18em;
  background-color: #f2f3f5;
  border-radius: 2em;
  display: flex;
  flex-direction: column;
}

.DetailsSectionFour,
.ImageSectionFour {
  height: 50%;
  width: 100%;
  background-color: #f2f3f5;
  border-radius: 2em;
}
.ImageSectionFour {
  overflow: hidden;
}
.ImageSectionFour img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.DetailsSectionFour {
  color: var(--colorAA);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1em;
}

/* everyOne */
.carSkeletonImg {
  height: 100%;
  width: 100%;
  object-fit: contain !important;
}
.conrtainer,
.conrtainerTwo,
.conrtainerThree,
.conrtainerFour {
  transition: ease-in-out 250ms;
}
.conrtainer:hover,
.conrtainerTwo:hover,
.conrtainerThree:hover,
.conrtainerFour:hover {
  filter: drop-shadow(0px 0px 5px var(--fadeBlack));
  transform: scale(1.025);
}

.info {
  font-size: 0.8em;
  text-transform: capitalize;
}
.BrandTitleWarper,
.cardTitleWarper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  text-transform: capitalize;
}
.BrandTitleWarper {
  justify-content: flex-start;
  color: var(--fadeBlack);
  font-size: 0.7em;
}
.BrandTitleWarper p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.BrandTitleWarper span {
  opacity: 0.6;
  font-size: 1.6em;
}

.wishlistIcon {
  color: var(--colorAA);
}
.wishlistIcon.active {
  color: var(--colorD);
  animation: addToWishlist ease-in-out 250ms;
}
@keyframes addToWishlist {
  from {
    transform: scale(0.7);
  }
  to {
    transform: scale(1.25);
  }
}

@media screen and (max-width: 860px) {
  .conrtainer,
  .conrtainerTwo,
  .conrtainerThree,
  .conrtainerFour {
    /* height: 11em;
    width: 11em; */
    font-size: 0.55em;
  }
}
