.SliderElements {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 70vh;
  min-width: 100vw;
  scroll-snap-align: center;
  position: relative;
  z-index: 0;
}

.contentWarper {
  position: absolute;
  height: 100%;
  width: 100%;
  background: linear-gradient(to right, #3623c973, transparent, transparent);
}
.content {
  height: 100%;
  width: 38%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 5em;
  color: white;
  gap: 1em;
}
.title {
  font-size: 3.6em;
  line-height: 0.8em;
  font-weight: 900;
}
.about {
  font-size: 1em;
  font-weight: 500;
  text-transform: capitalize;
}

@media screen and (max-width: 860px) {
  .contentWarper {
    background: linear-gradient(to bottom, #3623c973, transparent, transparent);
  }
  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 1em;
    margin-left: 0px;
    margin-top: 2.5em;
    color: white;
    gap: 1em;
  }
  .title {
    font-size: 2em;
    text-align: center;
    line-height: 0.8em;
    font-weight: 900;
  }
  .about {
    text-align: center;
    font-size: 0.8em;
    font-weight: 600;
    text-transform: capitalize;
  }
}
