@import url(https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap);
.HoverDropDown_MenuTitle__owGFs {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 0.5em;
  white-space: nowrap;
  cursor: pointer;
}
.HoverDropDown_MenuTitleText__mN9jD > * {
  cursor: pointer;
  font-weight: 600;
  text-transform: capitalize;
}
.HoverDropDown_dropdown__BR0hb {
  position: relative;
  display: inline-block;
  padding: 0.5em;
}

.HoverDropDown_dropdown_content_left__1m3pF,
.HoverDropDown_dropdown_content_right__15YyR {
  margin-top: 1em;
  z-index: 5;
  border-radius: 0.5em;
  box-shadow: 0px 0px 5px rgb(200, 200, 200);
  color: black;
  background-color: white;
  padding: 0.5em 0em;
  display: flex;
  flex-direction: column;
  /* display: none; */
  -webkit-transform: scale(0);
          transform: scale(0);
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
  transition: ease-in-out 300ms;
  position: absolute;
  /* right: 0px; */
}
.HoverDropDown_dropdown_content_left__1m3pF *,
.HoverDropDown_dropdown_content_right__15YyR * {
  cursor: pointer;
}
.HoverDropDown_dropdown_content_left__1m3pF {
  left: 0;
}
.HoverDropDown_dropdown_content_right__15YyR {
  right: 0;
}
.HoverDropDown_dropdown_content_left__1m3pF::before,
.HoverDropDown_dropdown_content_right__15YyR::before {
  content: "";
  top: 0px;
  border: 0.8em solid transparent;
  border-bottom-color: white;
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
  position: absolute;
}
.HoverDropDown_dropdown_content_left__1m3pF::before {
  left: 10%;
}
.HoverDropDown_dropdown_content_right__15YyR::before {
  right: 10%;
}
.HoverDropDown_dropdown__BR0hb:hover .HoverDropDown_menuIcon__16HvL {
  transition: ease-in-out 100ms;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.HoverDropDown_dropdown__BR0hb:hover .HoverDropDown_dropdown_content_left__1m3pF,
.HoverDropDown_dropdown__BR0hb:hover .HoverDropDown_dropdown_content_right__15YyR {
  display: flex;
  -webkit-transform: scale(1);
          transform: scale(1);
  transition: ease-in-out 300ms;
  /* min-height: 10em; */
}

.Slider_Slider__1sRSJ {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: 0;
}
.Slider_SlideWarper__7YrgH {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: none;
  scroll-behavior: smooth;
  width: 100vw;
  -ms-scroll-snap-type: mandatory;
      scroll-snap-type: mandatory;
  -ms-scroll-snap-points-x: repeat(100%);
      scroll-snap-points-x: repeat(100%);
  -ms-scroll-snap-type: x mandatory;
      scroll-snap-type: x mandatory;
}
.Slider_SlideWarper__7YrgH::-webkit-scrollbar {
  display: none;
}
.Slider_ScrollElement__23IVh {
  min-width: 100vw;
  max-width: 100vw;
}
.Slider_leftArrow__2CqIT,
.Slider_rightArrow__2mgNH {
  visibility: hidden;
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 33%;
  padding: 0.5em;
  background-color: white;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  transition: ease-in-out 100ms;
}
.Slider_leftArrow__2CqIT {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
}
.Slider_rightArrow__2mgNH {
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
}
.Slider_Slider__1sRSJ:hover .Slider_leftArrow__2CqIT {
  visibility: visible;
  -webkit-transform: translateX(0%);
          transform: translateX(0%);
}
.Slider_Slider__1sRSJ:hover .Slider_rightArrow__2mgNH {
  visibility: visible;
  -webkit-transform: translateX(0%);
          transform: translateX(0%);
}
.Slider_leftArrow__2CqIT {
  left: 0px;
  border-radius: 0em 0.25em 0.25em 0em;
}
.Slider_rightArrow__2mgNH {
  right: 0px;
  border-radius: 0.25em 0em 0em 0.25em;
}
.Slider_DotsWarper__2nGu2 {
  padding: 0.5em;
  position: absolute;
  bottom: 0px;
  display: flex;
  flex-direction: row;
  grid-gap: 0.8em;
  gap: 0.8em;
}
.Slider_Dots__2dtTO {
  height: 0.8em;
  width: 0.8em;
  border-radius: 50%;
  background-color: transparent;
  outline: 0.18em solid var(--colorB);
  cursor: pointer;
}
.Slider_activeDots__1V_f1 {
  background-color: var(--colorE);
}

@media screen and (max-width: 860px) {
  .Slider_leftArrow__2CqIT,
  .Slider_rightArrow__2mgNH {
    display: none;
  }
}

.BottomShutter_container__1nam6 {
  position: fixed;
  height: 100vh;
  width: 100vw;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
.BottomShutter_container__1nam6::-webkit-scrollbar {
  display: none;
}
.BottomShutter_contentWarper__3F659 {
  border-radius: 2.5em;
  overflow: hidden;
}
.BottomShutter_content__2lZC6 {
  background-color: white;
  width: unset;
  padding: 2.5em;
  position: relative;
  bottom: 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  max-height: 80vh;
  overflow-x: hidden;
  overflow-y: auto;
  /* scrollbar-width: none; */
  scroll-behavior: smooth;
}

/* animation part */
.BottomShutter_myNodeEnterActive__3oHR9 .BottomShutter_content__2lZC6 {
  -webkit-transform: scale(0);
          transform: scale(0);
}
.BottomShutter_myNodeEnterDone__3Mrps .BottomShutter_content__2lZC6 {
  -webkit-transform: scale(1);
          transform: scale(1);
  transition: -webkit-transform 280ms;
  transition: transform 280ms;
  transition: transform 280ms, -webkit-transform 280ms;
}
.BottomShutter_myNodeExitActive__1pIn6 .BottomShutter_content__2lZC6 {
  -webkit-transform: scale(1);
          transform: scale(1);
  transition: -webkit-transform 500ms;
  transition: transform 500ms;
  transition: transform 500ms, -webkit-transform 500ms;
}
.BottomShutter_myNodeExitDone__tGdSs .BottomShutter_content__2lZC6 {
  -webkit-transform: scale(0);
          transform: scale(0);
}

.BottomShutter_content__2lZC6::before {
  content: "";
  width: 10%;
  height: 0.25em;
  border-radius: 1em;
  background-color: gray;
  position: absolute;
  top: 1em;
  cursor: pointer;
}

@media screen and (max-width: 860px) {
  .BottomShutter_container__1nam6 {
    justify-content: flex-end;
  }
  .BottomShutter_contentWarper__3F659 {
    width: 100%;
    border-radius: 2em 2em 0em 0em;
  }
  .BottomShutter_content__2lZC6 {
    width: 100%;
    padding: 2.5em 0em;
  }
  /* animation part */
  .BottomShutter_myNodeEnterActive__3oHR9 .BottomShutter_content__2lZC6 {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
  .BottomShutter_myNodeEnterDone__3Mrps .BottomShutter_content__2lZC6 {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    transition: -webkit-transform 280ms;
    transition: transform 280ms;
    transition: transform 280ms, -webkit-transform 280ms;
  }
  .BottomShutter_myNodeExitActive__1pIn6 .BottomShutter_content__2lZC6 {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
    transition: -webkit-transform 280ms;
    transition: transform 280ms;
    transition: transform 280ms, -webkit-transform 280ms;
  }
  .BottomShutter_myNodeExitDone__tGdSs .BottomShutter_content__2lZC6 {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

.ImageShowCase_container__3d1-z {
  width: 100%;
  height: 35em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* background-color: red; */
}
.ImageShowCase_HeroElementWarper__3dybt {
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 0.25em;
  margin: 0.5em;
  padding: 0.5em;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.ImageShowCase_imageIndex__3yXOC {
  background-color: white;
  padding: 0.25em 0.5em;
  border-radius: 0.25em;
  bottom: 1em;
  right: 1em;
  position: absolute;
}
.ImageShowCase_leftArrow__2QDWk,
.ImageShowCase_rightArrow__3dyO7 {
  visibility: hidden;
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 21%;
  padding: 0.5em;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  transition: ease-in-out 100ms;
}
.ImageShowCase_leftArrow__2QDWk {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
}
.ImageShowCase_rightArrow__3dyO7 {
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
}
.ImageShowCase_HeroElementWarper__3dybt:hover .ImageShowCase_leftArrow__2QDWk {
  visibility: visible;
  -webkit-transform: translateX(0%);
          transform: translateX(0%);
}
.ImageShowCase_HeroElementWarper__3dybt:hover .ImageShowCase_rightArrow__3dyO7 {
  visibility: visible;
  -webkit-transform: translateX(0%);
          transform: translateX(0%);
}
.ImageShowCase_leftArrow__2QDWk {
  left: 0px;
  border-radius: 0em 0.25em 0.25em 0em;
}
.ImageShowCase_rightArrow__3dyO7 {
  right: 0px;
  border-radius: 0.25em 0em 0em 0.25em;
}
.ImageShowCase_leftArrow__2QDWk:hover,
.ImageShowCase_rightArrow__3dyO7:hover {
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.5);
}

.ImageShowCase_SliderImage__3I_A6 {
  height: 5em;
  width: 8em;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0em 0.5em;
  border-radius: 0.25em;
}

.ImageShowCase_SliderImage__3I_A6.ImageShowCase_active__361SV {
  outline: 2px solid var(--colorA);
}

@media screen and (max-width: 1051px) {
  .ImageShowCase_leftArrow__2QDWk,
  .ImageShowCase_rightArrow__3dyO7 {
    visibility: visible;
    background-color: rgba(255, 255, 255, 0.6);
  }
  .ImageShowCase_leftArrow__2QDWk {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
  .ImageShowCase_rightArrow__3dyO7 {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
}
@media screen and (max-width: 560px) {
  .ImageShowCase_container__3d1-z {
    height: 25em;
  }
}

.CardHolder_layout__2g_fQ {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0em 2em;
}
.CardHolder_container__1i1BB {
  width: 100%;
  max-width: var(--layoutWidth);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  padding: 1em;
  position: relative;
  overflow-x: hidden;
}
.CardHolder_titleWarper__3unA9 {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.CardHolder_CardWarper__1FUSW {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: none;
  scroll-behavior: smooth;
  grid-gap: 1.4em;
  gap: 1.4em;
  width: 100%;
  max-width: var(--layoutWidth);
  padding: 0.5em;
}
.CardHolder_CardWarper__1FUSW::-webkit-scrollbar {
  display: none;
}
.CardHolder_leftArrow__2po6m,
.CardHolder_rightArrow__3iXex {
  visibility: hidden;
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 33%;
  padding: 0.5em;
  background-color: white;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  transition: ease-in-out 100ms;
}
.CardHolder_leftArrow__2po6m {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
}
.CardHolder_rightArrow__3iXex {
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
}
.CardHolder_CardWarper__1FUSW:hover .CardHolder_leftArrow__2po6m {
  visibility: visible;
  -webkit-transform: translateX(0%);
          transform: translateX(0%);
}
.CardHolder_CardWarper__1FUSW:hover .CardHolder_rightArrow__3iXex {
  visibility: visible;
  -webkit-transform: translateX(0%);
          transform: translateX(0%);
}
.CardHolder_leftArrow__2po6m {
  left: 0px;
  border-radius: 0em 0.25em 0.25em 0em;
}
.CardHolder_rightArrow__3iXex {
  right: 0px;
  border-radius: 0.25em 0em 0em 0.25em;
}
.CardHolder_leftArrow__2po6m:hover,
.CardHolder_rightArrow__3iXex:hover {
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.5);
}
@media screen and (max-width: 860px) {
  .CardHolder_leftArrow__2po6m,
  .CardHolder_rightArrow__3iXex {
    display: none;
  }
}

.FilterItem_container__1oqyi {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}
.FilterItem_header__3LrV2 {
  width: 100%;
  padding: 0.5em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.FilterItem_shorting__3busu {
  background-color: white;
  padding: 0.8em;
  /* border-radius: 0.25em; */
  /* border: 1px solid var(--colorA); */
  width: 14em;
  font-size: 1em;
}
.FilterItem_ContentSection__2AgNy {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}
.FilterItem_FilterWarper__3S8Cq {
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 98vh;
  overflow: hidden;
  overflow-y: auto;
  position: -webkit-sticky;
  position: sticky;
  top: 1vh;
  scrollbar-width: none;
  border-radius: 0.5em;
  border: 1px solid var(--lightDark);
  background-color: white;
}
.FilterItem_FilterWarper__3S8Cq::-webkit-scrollbar {
  display: none;
}
.FilterItem_ItemWarper__1GtHS {
  width: 75%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.FilterItem_filterBtn__2-e5u {
  position: fixed;
  padding: 0.5em;
  border-radius: 50%;
  height: 2.8em;
  width: 2.8em;
  background-color: var(--colorB);
  bottom: 5em;
  right: 1em;
  display: none;
  border: none;
}

@media screen and (max-width: 860px) {
  .FilterItem_ItemWarper__1GtHS {
    justify-content: center;
    width: 100%;
  }
  .FilterItem_FilterWarper__3S8Cq {
    width: 80%;
  }
  .FilterItem_filterBtn__2-e5u {
    display: block;
  }
}

.DoubleRangeSlider_container__3223U {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2.5em;
  position: relative;
}

.DoubleRangeSlider_slider__3V3u_ {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.DoubleRangeSlider_slider__track__3r579,
.DoubleRangeSlider_slider__range__1c2HA,
.DoubleRangeSlider_slider__left_value__1vbeu,
.DoubleRangeSlider_slider__right_value__m3HHV {
  position: absolute;
}

.DoubleRangeSlider_slider__track__3r579,
.DoubleRangeSlider_slider__range__1c2HA {
  border-radius: 3px;
  height: 5px;
}

.DoubleRangeSlider_slider__track__3r579 {
  background-color: var(--colorB);
  width: 100%;
  z-index: 1;
  position: absolute;
}

.DoubleRangeSlider_slider__range__1c2HA {
  background-color: var(--colorA);
  z-index: 2;
}

.DoubleRangeSlider_slider__left_value__1vbeu,
.DoubleRangeSlider_slider__right_value__m3HHV {
  color: var(--colorA);
  font-size: 0.8em;
  font-weight: 800;
  position: absolute;
  top: 0px;
}

.DoubleRangeSlider_slider__left_value__1vbeu {
  left: 0px;
}

.DoubleRangeSlider_slider__right_value__m3HHV {
  right: 0px;
}

/* Removing the default appearance */
.DoubleRangeSlider_thumb__jZOPo,
.DoubleRangeSlider_thumb__jZOPo::-webkit-slider-thumb {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

.DoubleRangeSlider_thumb__jZOPo {
  pointer-events: none;
  position: absolute;
  height: 0em;
  width: 100%;
  outline: none;
}

.DoubleRangeSlider_thumb_zindex_3__3Vrs4 {
  z-index: 3;
}

.DoubleRangeSlider_thumb_zindex_4__195dG {
  z-index: 4;
}

.DoubleRangeSlider_thumb_zindex_5__25Fxx {
  z-index: 5;
}

.DoubleRangeSlider_thumb__jZOPo::-webkit-slider-thumb {
  background-color: var(--colorA);
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px var(--colorA);
  cursor: pointer;
  height: 2em;
  width: 2em;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
  -webkit-transform: translateY(-8%);
          transform: translateY(-8%);
  -webkit-transition: all 180ms;
  transition: all 180ms;
}
.DoubleRangeSlider_thumb__jZOPo::-moz-range-thumb {
  background-color: var(--colorA);
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px var(--colorA);
  cursor: pointer;
  height: 2em;
  width: 2em;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
  transform: translateY(0%);
  -moz-transition: all 180ms;
  transition: all 180ms;
}

.DoubleRangeSlider_thumb__jZOPo::-webkit-slider-thumb:active {
  height: 1.6em;
  width: 1.6em;
  background-color: var(--colorA);
  -webkit-filter: drop-shadow(0px 0px 5px var(--colorA));
          filter: drop-shadow(0px 0px 5px var(--colorA));
}
.DoubleRangeSlider_thumb__jZOPo::-moz-range-thumb:active {
  height: 1.6em;
  width: 1.6em;
  background-color: var(--colorA);
  filter: drop-shadow(0px 0px 5px var(--colorA));
}

.Button_ButtonA__2W6ik,
.Button_ButtonB__1VMWH {
  cursor: pointer;
  border: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  grid-gap: 0.5em;
  gap: 0.5em;
}
.Button_ButtonA__2W6ik {
  padding: 1.2em 2.5em;
  border-radius: 0.5em;
  font-weight: bold;
  text-transform: uppercase;
  font-weight: 800;
  margin: 1em;
}
.Button_ButtonB__1VMWH {
  padding: 1em 2.5em;
  border-radius: 2em;
  font-weight: bold;
  text-transform: uppercase;
  font-weight: 800;
  margin: 1em;
}
.Button_ButtonA__2W6ik:hover,
.Button_ButtonB__1VMWH:hover {
  -webkit-filter: brightness(0.85);
          filter: brightness(0.85);
}
.Button_ButtonA__2W6ik:active,
.Button_ButtonB__1VMWH:active {
  -webkit-filter: brightness(0.8);
          filter: brightness(0.8);
}
.Button_AOne__3-8p6 {
  background-color: #3523c9;
  color: white;
}
.Button_ATwo__2czgI {
  background-color: #b6b3ff;
  color: black;
}
.Button_AThree__1PDqX {
  background-color: #776cff;
  color: white;
}
.Button_AFour__2JhUJ {
  background-color: #f8047e;
  color: white;
}
.Button_AFive__3Jgk4 {
  background-color: #f7589f;
  color: white;
}

.Button_ButtonB__1VMWH {
  padding: 0.5em 1em;
  border-radius: 0.5em;
  font-weight: bold;
  text-transform: uppercase;
  font-weight: 500;
  background-color: transparent;
  font-weight: 500;
  white-space: nowrap;
}
.Button_BOne__hfRoo {
  border: 0.2em solid #3523c9;
  color: #3523c9;
}
.Button_BTwo__3NZwf {
  border: 0.2em solid #b6b3ff;
  color: #b6b3ff;
}
.Button_BThree__2AD1P {
  border: 0.2em solid #776cff;
  color: #776cff;
}
.Button_BFour__IuyMQ {
  border: #f8047e;
  color: #f8047e;
}
.Button_BFive__gs-nW {
  border: #f7589f;
  color: #f7589f;
}

@media screen and (max-width: 1160px) {
  .Button_ButtonA__2W6ik,
  .Button_ButtonB__1VMWH {
    font-size: 0.8em;
    padding: 0.5em;
    margin: 0.25em;
  }
}

.InputField_lable__1sDx- {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  color: rgb(80, 80, 80);
  margin: 1em 0em;
  position: relative;
}
.InputField_name__YJJIb {
  text-transform: capitalize;
  margin-bottom: 0.5em;
  font-weight: 500;
  font-size: 1em;
}
.InputField_textareaWarper__1v69a,
.InputField_inputWarper__3Jl62 {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: rgb(245, 245, 245);
  padding: 0.1em 0.5em;
  grid-gap: 1em;
  gap: 1em;
  border-radius: 0.25em;
  transition: 80ms ease-in-out;
  box-shadow: 0px 1px 1px rgb(180, 180, 180);
}
.InputField_textareaWarper__1v69a:focus-within,
.InputField_inputWarper__3Jl62:focus-within {
  outline: 2.5px solid var(--colorC);
}
.InputField_input__2J1AC {
  background-color: transparent;
  border: none;
  height: 100%;
  width: 100%;
  height: 2.5em;
  outline: none;
}
.InputField_textareaWarper__1v69a {
  align-items: flex-start;
}
.InputField_textarea__2sohK {
  background-color: transparent;
  border: none;
  width: 100%;
  outline: none;
  font-family: "Nunito", sans-serif;
}
.InputField_WrongInput__2JKSC {
  outline-color: rgb(255, 50, 50);
  outline: 2.5px solid rgb(255, 80, 80) !important;
  background-color: rgba(255, 50, 50, 0.1);
}
/* Custom File Input  */
.InputField_customFileInput_A_wrap__2svwY {
  position: relative;
  display: flex;
  flex-direction: column !important;
  align-items: center !important;
  padding: 0.25em !important;
}
.InputField_customFileInput_A_wrap__2svwY img {
  width: 100%;
  max-width: 15em;
  height: auto;
  border-radius: 0.5em;
  margin: 0.5em;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
}
.InputField_customFileInput_A_span__3Kg9w {
  padding: 1em;
  cursor: pointer;
  border-radius: 0.5em;
  background-color: var(--colorB);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 1em;
  gap: 1em;
  color: black;
  font-weight: 600;
  text-transform: capitalize;
}
.InputField_customFileInput_A_input__I6feK {
  position: absolute;
  z-index: -1;
}

.InputField_errorMessageWarper__35nMq {
  position: absolute;
  bottom: 0px;
  -webkit-transform: translateY(110%);
          transform: translateY(110%);
  text-transform: capitalize;
}

/* Custom File Input  */
.FileInput_customFileInput_A_wrap__1bqbs {
  position: relative;
  display: flex;
  flex-direction: column !important;
  align-items: center !important;
  padding: 0.25em !important;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  padding: 0.5em;
}
.FileInput_customFileInput_A_span__xnn6b {
  padding: 1em;
  cursor: pointer;
  border-radius: 0.5em;
  width: 100%;
  height: 11em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 1em;
  gap: 1em;
  color: black;
  font-weight: 600;
  text-transform: capitalize;
}
.FileInput_customFileInput_A_input__38WD4 {
  position: absolute;
  z-index: -1;
}
.FileInput_inputWarper__3AeAX {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: rgb(248, 248, 248);
  padding: 0.1em 0.5em;
  grid-gap: 1em;
  gap: 1em;
  border-radius: 0.25em;
}
.FileInput_wrongInput__1o0YR {
  outline-color: rgb(255, 50, 50);
  outline: 2.5px solid rgb(255, 80, 80) !important;
  background-color: rgba(255, 50, 50, 0.1);
}

.FileInput_input__sc9Tf {
  background-color: transparent;
  border: none;
  height: 100%;
  width: 100%;
  height: 2.5em;
  outline: none;
}
.FileInput_actionWarper__1U8ZK {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  height: 100%;
  width: 100%;
}
.FileInput_clearBtn__1D9eB {
  color: var(--colorD);
}

.StageExpand_container__2ScQS {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  grid-gap: 1em;
  gap: 1em;
  overflow: hidden;
}
.StageExpand_header__9340b {
  background-color: var(--colorA);
  border-radius: 0.25em;
  padding: 0.5em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-transform: uppercase;
  width: 100%;
}
.StageExpand_infoWarper__2T8su {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 1em;
  gap: 1em;
}
.StageExpand_stage__1G5MP {
  height: 2em;
  width: 2em;
  text-align: center;
  padding: 0.5em;
  background-color: white;
  font-size: 0.8em;
  border-radius: 0.5em;
  font-weight: bold;
  color: var(--colorA);
}
.StageExpand_title__3PKM3 {
  color: white;
}
.StageExpand_ChildrenWarper__qRbKa {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 1em;
  gap: 1em;
  transition: ease-in-out 1s;
}
.StageExpand_ChildrenWarperAnimate__URFxB {
  transition: ease-in-out 1s;
  height: 0em;
}

.Stepper_container__3Nmue {
  width: 100%;
  padding: 1em;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.Stepper_progress__Yja73 {
  height: 0.5em;
  background-color: var(--colorA);
  border-radius: 1em;
  width: 50%;
}
.Stepper_btnWarper__n500X {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.TabData_container__2Ty4f {
  border-radius: 0.5em;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.TabData_header__rIL33 {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  background-color: var(--colorC);
  border-radius: 0.5em;
  color: white;
  font-weight: 600;
  /* position: sticky; */
  top: 10em;
  width: 100%;
  z-index: 1;
}
.TabData_tabes__2RuGp {
  width: 100%;
  text-align: center;
  background-color: none;
  border: none;
  padding: 1em;
  cursor: pointer;
  transition: all 250ms;
  text-transform: uppercase;
  border-radius: 0.5em;
  white-space: nowrap;
}
.TabData_tabes__2RuGp:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.TabData_content__2xGtt {
  border-top: none;
  display: flex;
  flex-direction: column;
}

.Select_container__2ch1m {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  transition: ease-in-out 150ms;
  border: 1px solid black;
  border-radius: 0.5em;
  cursor: pointer;
}
.Select_container__2ch1m * {
  cursor: pointer;
}
.Select_optionWarper__1lrQk {
  position: absolute;
  max-height: 0em;
  -webkit-transform: translateY(-10%);
          transform: translateY(-10%);
  align-self: center;
  width: 100%;
  z-index: 1;
  overflow: hidden;
  top: 100%;
  transition: ease-in-out 250ms;
  opacity: 0;
  /* border: 1px solid black; */
  border-radius: 0.5em;
  box-shadow: 0px 0px 5px rgba(80, 80, 80, 0.5);
}
.Select_container__2ch1m:hover .Select_optionWarper__1lrQk {
  max-height: unset;
  transition: ease-in-out 250ms;
  opacity: 1;
  -webkit-transform: translateY(0%);
          transform: translateY(0%);
}
.Select_option__1CqGH {
  padding: 0.8em;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: start;
  font-size: 1em;
  transition: ease-in-out 150ms;
}
.Select_option__1CqGH:hover {
  -webkit-filter: brightness(88%);
          filter: brightness(88%);
}

.ProgressIndication_container__2Ycb9 {
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin: 2.5em;
  z-index: 1;
}
.ProgressIndication_container__2Ycb9::before {
  content: "";
  position: absolute;
  height: 11%;
  width: 100%;
  background-color: var(--colorB);
  transition: all 500ms;
}

.ProgressIndication_progressPoint__3EwRw {
  border: 2.5px solid var(--colorA);
  border-radius: 0.5em;
  height: 1.75em;
  width: 1.75em;
  color: white;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: black;
  background-color: white;
}
.ProgressIndication_progressPoint__3EwRw.ProgressIndication_active__2FiBO {
  background-color: var(--colorA);
  color: white;
  font-weight: 800;
}
.ProgressIndication_progressPoint__3EwRw.ProgressIndication_pointActive__3OEaX {
  background-color: var(--colorA);
  color: white;
}
.ProgressIndication_progressPoint__3EwRw.ProgressIndication_pointActive__3OEaX::after {
  content: "";
  height: 0.5em;
  width: 0.5em;
  border-radius: 2.5px;
  border: 2.5px solid var(--colorA);
  border-top-color: transparent;
  border-left-color: transparent;
  position: absolute;
  top: -60%;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.ProgressIndication_progress__3MG-i {
  position: absolute;
  height: 21%;
  width: 0%;
  border-radius: 1em;
  background-color: var(--colorA);
  transition: all 500ms;
}

.EmptyPage_container__2TSLO {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  grid-gap: 1em;
  gap: 1em;
  padding: 2em;
}
.EmptyPage_ImageSection__3Ejul {
  width: 11em;
  height: 11em;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.ExpandCollapse_container__13qF4 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1em 2em;
  width: 100%;
  border-radius: 0.25em;
  grid-gap: 1em;
  gap: 1em;
  transition: ease-in-out 250ms;
  overflow: hidden;
}
.ExpandCollapse_container__13qF4.ExpandCollapse_Collapse__4fEp9 {
  border: 1px solid var(--fadeBlack);
}
.ExpandCollapse_container__13qF4.ExpandCollapse_Expand__23anf {
  border: 1px solid transparent;
  background-color: var(--lightPrime);
}
.ExpandCollapse_titleWarper__14vFB {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-transform: capitalize;
  font-size: 1.25em;
  font-weight: 400;
}
.ExpandCollapse_Contents__3OQau {
  text-align: start;
}

/* animation part */
.ExpandCollapse_myNodeEnterActive__3Mf40 {
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
}
.ExpandCollapse_myNodeEnterDone__3QiYy {
  -webkit-transform: translateY(0%);
          transform: translateY(0%);
  transition: ease-in-out 250ms;
}
.ExpandCollapse_myNodeExitActive__3Ij9O {
  -webkit-transform: translateY(0%);
          transform: translateY(0%);
  transition: ease-in-out 250ms;
}
.ExpandCollapse_myNodeExitDone__3sPtO {
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
}

@media screen and (max-width: 860px) {
  .ExpandCollapse_container__13qF4 {
    font-size: 0.8em;
  }
}

.PreLoader_container__c7MV- {
  background-color: rgb(245, 245, 245);
  position: fixed;
  height: 100%;
  width: 100%;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
}
.PreLoader_Two__1Z7Gh {
  height: 15em;
  width: 15em;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: inset -5px -5px 8px rgb(200, 200, 200),
    inset 5px 5px 8px rgb(250, 250, 250), 5px 5px 8px rgb(200, 200, 200),
    -5px -5px 8px rgb(250, 250, 250);
  position: relative;
}
.PreLoader_Two__1Z7Gh::after {
  position: absolute;
  content: "";
  height: 100%;
  border-radius: 50%;
  width: 100%;
  border: 2px solid blue;
  border-top-color: transparent;
  border-bottom-color: transparent;
  -webkit-animation: PreLoader_rotate__rHCpH linear infinite 1000ms;
          animation: PreLoader_rotate__rHCpH linear infinite 1000ms;
}
.PreLoader_One__2IXaj {
  height: 8em;
  width: 8em;
  border-radius: 50%;
  box-shadow: inset 5px 5px 11px rgb(200, 200, 200),
    inset -5px -5px 11px rgb(250, 250, 250);
  border: 1em solid rgb(250, 250, 250);
  background-color: transparent;
  position: relative;
}
.PreLoader_One__2IXaj::after {
  position: absolute;
  content: "";
  height: 100%;
  border-radius: 50%;
  width: 100%;
  border: 2px solid red;
  border-top-color: transparent;
  border-bottom-color: transparent;
  animation: PreLoader_rotate__rHCpH linear reverse infinite 2000ms;
}

@-webkit-keyframes PreLoader_rotate__rHCpH {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes PreLoader_rotate__rHCpH {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.ListItems_container__2lLc0 {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  padding: 1em;
}
.ListItems_headerSection__1Z1c5 {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1em 0em;
  text-transform: capitalize;
}
.ListItems_headerSection__1Z1c5 button {
  padding: 0.25em 0.75em;
  background-color: transparent;
  border: 2px solid var(--colorC);
  color: var(--colorC);
  border-radius: 0.25em;
  text-transform: capitalize;
}
.ListItems_items__AtkgZ {
  padding: 1em 0em;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 1em;
  gap: 1em;
}

.Avatar_container__cAorw {
  display: flex;
  text-transform: capitalize;
  grid-gap: 0.5em;
  gap: 0.5em;
}
.Avatar_under_text__VP1I9 {
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.Avatar_right_text__1UTnY {
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.Avatar_avatarWarper__29J6I {
  border-radius: 50%;
  min-height: 2.5em;
  min-width: 2.5em;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.AlertPopUp_container__1TrbC {
  position: fixed;
  margin: 1em 1em;
  width: 80%;
  top: 0px;
  z-index: 99999;
}
.AlertPopUp_container__1TrbC * {
  color: white;
}

* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
  cursor: auto;
  text-decoration: none;
  scroll-behavior: smooth;
  scrollbar-color: #776cff #b6b3ff;
  scrollbar-color: var(--colorC) var(--colorB);
  scrollbar-width: thin;
}
::-webkit-scrollbar {
  width: 0.5em;
}
::-webkit-scrollbar-thumb {
  background: #776cff;
  background: var(--colorC);
}
::-webkit-scrollbar-track {
  background: #b6b3ff;
  background: var(--colorB);
}
a {
  color: unset;
}
:root {
  --colorA: #3523c9;
  --colorAA: #0d006e;
  --colorAAA: #090050;
  --colorB: #b6b3ff;
  --colorC: #776cff;
  --colorD: #f8047e;
  --colorE: #f7589f;
  --lightPrime: #f0f0ff;
  --lightDark: rgb(221, 221, 221);
  --fadeBlack: rgb(160, 160, 160);
  --layoutWidth: 1400px;
}

/* icon sizing */
.material-icons,
.material-icons-round {
  cursor: pointer;
}
.material-icons.md-18 {
  font-size: 18px;
}
.material-icons.md-24 {
  font-size: 24px;
}
.material-icons.md-36 {
  font-size: 36px;
}
.material-icons.md-48 {
  font-size: 48px;
}
.material-icons-round.md-18 {
  font-size: 18px;
}
.material-icons-round.md-24 {
  font-size: 24px;
}
.material-icons-round.md-36 {
  font-size: 36px;
}
.material-icons-round.md-48 {
  font-size: 48px;
}
.material-icons-outlined.md-18 {
  font-size: 18px;
}
.material-icons-outlined.md-24 {
  font-size: 24px;
}
.material-icons-outlined.md-36 {
  font-size: 36px;
}
.material-icons-outlined.md-48 {
  font-size: 48px;
}
/* icon color */
.material-icons.md-light {
  color: rgba(255, 255, 255, 1);
}
.material-icons-round.md-light {
  color: rgba(255, 255, 255, 1);
}
.material-icons-outlined.md-light {
  color: rgba(255, 255, 255, 1);
}
.material-icons.md-dark {
  color: black;
}
.material-icons-round.md-dark {
  color: black;
}
.material-icons-outlined.md-dark {
  color: black;
}
.material-icons.md-dark-light {
  color: rgb(50, 50, 50);
}
.material-icons-round.md-dark-light {
  color: rgb(50, 50, 50);
}
.material-icons-outlined.md-dark-light {
  color: rgb(50, 50, 50);
}
.material-icons.md-red {
  color: rgb(255, 80, 80);
}
.material-icons-round.md-red {
  color: rgb(255, 80, 80);
}
.material-icons-outlined.md-red {
  color: rgb(255, 80, 80);
}
.material-icons.md-blue {
  color: #3523c9;
  color: var(--colorA);
}
.material-icons-round.md-blue {
  color: #3523c9;
  color: var(--colorA);
}
.material-icons-outlined.md-blue {
  color: #3523c9;
  color: var(--colorA);
}

[data-fade] {
  opacity: 0.6;
}
[data-curser]:hover {
  opacity: 0.8;
  cursor: pointer;
}

body {
  margin: 0;
  font-family: "Nunito", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  width: 100%;
  position: relative;
}
#root {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* custom loader */
/* loaderOne */
.loaderOneWhite {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  grid-gap: 0.5em;
  gap: 0.5em;
}
.loaderOneWhite::before {
  content: "";
  min-height: 1em;
  min-width: 1em;
  max-height: 16em;
  max-width: 16em;
  background-color: transparent;
  border: 2.5px solid white;
  border-top-color: transparent;
  border-bottom-color: transparent;
  border-radius: 50%;
  -webkit-animation: rotate cubic-bezier(0.68, -0.55, 0.27, 1.55) infinite 1s;
          animation: rotate cubic-bezier(0.68, -0.55, 0.27, 1.55) infinite 1s;
}
/* loaderOne */
.loaderOneBlue {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #3523c9;
  color: var(--colorA);
  grid-gap: 0.5em;
  gap: 0.5em;
}
.loaderOneBlue::before {
  content: "";
  min-height: 1em;
  min-width: 1em;
  max-height: 16em;
  max-width: 16em;
  background-color: transparent;
  border: 2.5px solid #3523c9;
  border: 2.5px solid var(--colorA);
  border-top-color: transparent;
  border-bottom-color: transparent;
  border-radius: 50%;
  -webkit-animation: rotate cubic-bezier(0.68, -0.55, 0.27, 1.55) infinite 1s;
          animation: rotate cubic-bezier(0.68, -0.55, 0.27, 1.55) infinite 1s;
}
button[disabled] {
  /* opacity: 0.8; */
  -webkit-filter: grayscale(0.5);
          filter: grayscale(0.5);
}
button[disabled],
button[disabled]:hover,
button[disabled]:active {
  cursor: not-allowed;
  -webkit-filter: grayscale(0.5);
          filter: grayscale(0.5);
}

[disabled] {
  cursor: not-allowed;
}

/* error message */
.EMS {
  color: red;
  font-size: 0.8em;
  display: flex;
}
.EMS::before {
  content: "*";
}
/* terms and condition */
.TMC::after {
  content: " *";
  color: red;
  /* font-size: 0.8em; */
}

/* cursor pointer all */
.CURALL,
.CURALL * {
  cursor: pointer;
}
/* text Capitalized */
.TCP {
  text-transform: capitalize;
}
/* cursor pointer */
.CP,
.CP * {
  cursor: pointer;
}

/* flex Row center and space Evenly */
.FRSA {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}
/* flex vertical center and space between */
.FRSB {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
/* flex column center and Gap 0.5em*/
.FCGP05 {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 0.5em;
  gap: 0.5em;
  width: 100%;
}
/*flex vertical column Center */
.FCVC {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}
/* padding 0.5em */
.PD05 {
  padding: 0.5em;
}
/* style title under line */
.STUL {
  position: relative;
  padding: 0.25em 0em;
  margin: 0.5em 0em;
}
.STUL::after {
  content: "";
  position: absolute;
  height: 0.1em;
  border-radius: 1em;
  width: 80%;
  bottom: 0;
  left: 0;
  background-color: #3523c9;
  background-color: var(--colorA);
}

.OF_Y {
  overflow: hidden;
  overflow-y: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* For Hide Arrows From Input Number  */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* scroll Reveal positions*/
.reveal {
  opacity: 0;
  transition: all 600ms ease;
  -webkit-transform: scale(90%);
          transform: scale(90%);
}
.reveal-active {
  opacity: 1;
  -webkit-transform: scale(100%);
          transform: scale(100%);
}

@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes rotate {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.popupConform {
  padding: 1em;
  border-radius: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-gap: 1em;
  gap: 1em;
  text-align: center;
}

/* skeleton animation */
.animateSkeleton {
  position: relative;
  /* animation: opacityy infinite 1200ms ease-in ; */
}
.animateSkeleton::after {
  height: 100%;
  content: "";
  width: 25%;
  top: 0;
  position: absolute;
  z-index: 1;
  -webkit-animation: left-to-right-loading infinite 1200ms 1000ms
    cubic-bezier(0, 0.63, 0.93, 0.01);
          animation: left-to-right-loading infinite 1200ms 1000ms
    cubic-bezier(0, 0.63, 0.93, 0.01);
}

/* Box Container */
.boxContainer {
  max-width: 420px;
  border: 1px solid #776cff;
  border: 1px solid var(--colorC);
  margin: 1em auto;
  padding: 0.5em;
  align-self: center;
  justify-self: center;
  border-radius: 0.25em;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 1em;
  gap: 1em;
}
.boxContainer .boxContainerTitle {
  color: #3523c9;
  color: var(--colorA);
}
.imageContainer {
  width: 100%;
  height: 100%;
  align-self: center;
  justify-self: center;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

@-webkit-keyframes left-to-right-loading {
  0% {
    left: -30%;
    background: linear-gradient(
      to right,
      rgba(0, 0, 0, 0),
      rgba(121, 121, 121, 0.24),
      rgba(0, 0, 0, 0)
    );
  }
  100% {
    left: 130%;
    background: linear-gradient(
      to right,
      rgba(0, 0, 0, 0),
      rgba(121, 121, 121, 0.24),
      rgba(0, 0, 0, 0)
    );
  }
}

@keyframes left-to-right-loading {
  0% {
    left: -30%;
    background: linear-gradient(
      to right,
      rgba(0, 0, 0, 0),
      rgba(121, 121, 121, 0.24),
      rgba(0, 0, 0, 0)
    );
  }
  100% {
    left: 130%;
    background: linear-gradient(
      to right,
      rgba(0, 0, 0, 0),
      rgba(121, 121, 121, 0.24),
      rgba(0, 0, 0, 0)
    );
  }
}

.SliderBanner_SliderElements__loQQm {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 70vh;
  min-width: 100vw;
  scroll-snap-align: center;
  position: relative;
  z-index: 0;
}

.SliderBanner_contentWarper__Pg1Vo {
  position: absolute;
  height: 100%;
  width: 100%;
  background: linear-gradient(to right, #3623c973, transparent, transparent);
}
.SliderBanner_content__3Jq7U {
  height: 100%;
  width: 38%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 5em;
  color: white;
  grid-gap: 1em;
  gap: 1em;
}
.SliderBanner_title__3DxgC {
  font-size: 3.6em;
  line-height: 0.8em;
  font-weight: 900;
}
.SliderBanner_about__1lFrk {
  font-size: 1em;
  font-weight: 500;
  text-transform: capitalize;
}

@media screen and (max-width: 860px) {
  .SliderBanner_contentWarper__Pg1Vo {
    background: linear-gradient(to bottom, #3623c973, transparent, transparent);
  }
  .SliderBanner_content__3Jq7U {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 1em;
    margin-left: 0px;
    margin-top: 2.5em;
    color: white;
    grid-gap: 1em;
    gap: 1em;
  }
  .SliderBanner_title__3DxgC {
    font-size: 2em;
    text-align: center;
    line-height: 0.8em;
    font-weight: 900;
  }
  .SliderBanner_about__1lFrk {
    text-align: center;
    font-size: 0.8em;
    font-weight: 600;
    text-transform: capitalize;
  }
}

.Footer_warper__7MJJe {
  background-color: var(--colorAAA);
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.Footer_container__3GBfd {
  width: 100%;
  max-width: var(--layoutWidth);
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr 5em;
}
.Footer_usefulLinksSection__3HwZO {
  grid-column-start: 1;
  grid-column-end: 3;
  width: 100%;
}
.Footer_contactSection__314MZ {
  grid-column-start: 3;
  width: 100%;
}
.Footer_bottomSection__1vIfn {
  grid-row-start: 2;
  grid-column-start: 1;
  grid-column-end: 4;
  width: 100%;
}
@media screen and (max-width: 1051px) {
  .Footer_warper__7MJJe {
    padding-bottom: 5em;
  }
}

@media screen and (max-width: 860px) {
  .Footer_container__3GBfd {
    grid-template-rows: 1fr 1fr 8em;
  }
  .Footer_usefulLinksSection__3HwZO {
    grid-column-start: 1;
    grid-column-end: 4;
    width: 100%;
    grid-row-start: 2;
  }
  .Footer_contactSection__314MZ {
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 1;
    width: 100%;
  }
  .Footer_bottomSection__1vIfn {
    grid-row-start: 3;
  }
}

.BottomSection_container__VOpD6 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: white;
  border-top: 1px solid var(--colorA);
  padding: 1em;
}
.BottomSection_container__VOpD6 span {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.BottomSection_socialMediaWarper__1VuQS {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  grid-gap: 0.5em;
  gap: 0.5em;
}
.BottomSection_Icons__3Kjau {
  cursor: pointer;
  z-index: 1;
}
.BottomSection_Icons__3Kjau * {
  cursor: pointer;
}
.BottomSection_IconsWarper__21AiG {
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 45px;
  max-width: 40px;
  padding: 0.5em;
  transition: ease-in-out 250ms;
}
.BottomSection_IconsWarper__21AiG::after {
  content: "";
  position: absolute;
  background-color: rgba(255, 255, 255);
  padding: 0% 0% 0 0;
  opacity: 0;
  border-radius: 50%;
  z-index: 0;
  transition: ease-in-out 250ms;
}
.BottomSection_IconsWarper__21AiG:hover::after,
.BottomSection_IconsWarper__21AiG:active::after {
  background-color: white;
  opacity: 1;
  padding: 100% 100% 0 0;
}
.BottomSection_IconsWarper__21AiG:active::after {
  background-color: white;
  opacity: 1;
  padding: 80% 80% 0 0;
}

.BottomSection_IconsWarper__21AiG .BottomSection_Icons__3Kjau {
  transition: all 250ms;
}
.BottomSection_IconsWarper__21AiG:nth-child(1):hover .BottomSection_Icons__3Kjau,
.BottomSection_IconsWarper__21AiG:nth-child(1):active .BottomSection_Icons__3Kjau {
  fill: #1877f2;
}
.BottomSection_IconsWarper__21AiG:nth-child(2):hover .BottomSection_Icons__3Kjau,
.BottomSection_IconsWarper__21AiG:nth-child(2):active .BottomSection_Icons__3Kjau {
  fill: #1da1f2;
}
.BottomSection_IconsWarper__21AiG:nth-child(3):hover .BottomSection_Icons__3Kjau,
.BottomSection_IconsWarper__21AiG:nth-child(3):active .BottomSection_Icons__3Kjau {
  fill: #ff0000;
}
.BottomSection_IconsWarper__21AiG:nth-child(4):hover .BottomSection_Icons__3Kjau,
.BottomSection_IconsWarper__21AiG:nth-child(4):active .BottomSection_Icons__3Kjau {
  fill: #e1306c;
}
.BottomSection_IconsWarper__21AiG:nth-child(5):hover .BottomSection_Icons__3Kjau,
.BottomSection_IconsWarper__21AiG:nth-child(5):active .BottomSection_Icons__3Kjau {
  fill: #0072b1;
}

@media screen and (max-width: 860px) {
  .BottomSection_container__VOpD6 {
    flex-direction: column;
    justify-content: space-evenly;
    padding: 0.5em;
  }
  .BottomSection_container__VOpD6 span {
    font-size: 0.8em;
  }
}

.ContactSection_container__3qthL {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ContactSection_titleWarper__31PY3 {
  background-color: var(--colorD);
  padding: 0.8em;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  grid-gap: 1em;
  gap: 1em;
  width: 100%;
  align-items: center;
}
.ContactSection_TalkIcon__3HrbX {
  border-radius: 50%;
  padding: 0.25em;
  background-color: white;
  color: var(--colorD);
}
.ContactSection_ContactLink__3IJR6 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 0.8em;
  gap: 0.8em;
  width: 100%;
  color: white;
  padding: 0.8em;
}
.ContactSection_contactIcon__3RiIo {
  color: var(--colorA);
  font-size: 2em;
}

.UsefulLinkSection_container__J7nn8 {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-content: flex-start;
  grid-gap: 0.5em;
  gap: 0.5em;
  padding: 2em;
  color: white;
}
.UsefulLinkSection_sectionWarper__3-1vR {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  grid-gap: 0.5em;
  gap: 0.5em;
}
.UsefulLinkSection_sectionWarper__3-1vR span {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  grid-gap: 0.25em;
  gap: 0.25em;
}
.UsefulLinkSection_links__2gNEZ p,
.UsefulLinkSection_links__2gNEZ {
  cursor: pointer;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.UsefulLinkSection_links__2gNEZ .UsefulLinkSection_Icon__3NPfW {
  color: var(--colorA);
  transition: all 250ms;
}
.UsefulLinkSection_links__2gNEZ:hover .UsefulLinkSection_Icon__3NPfW,
.UsefulLinkSection_links__2gNEZ:active .UsefulLinkSection_Icon__3NPfW {
  color: var(--colorB);
}
.UsefulLinkSection_links__2gNEZ:hover {
  -webkit-filter: brightness(80%);
          filter: brightness(80%);
}
.UsefulLinkSection_links__2gNEZ:active {
  -webkit-filter: brightness(60%);
          filter: brightness(60%);
}

@media screen and (max-width: 1051px) {
  .UsefulLinkSection_container__J7nn8 {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media screen and (max-width: 860px) {
  .UsefulLinkSection_container__J7nn8 {
    grid-template-columns: repeat(2, 1fr);
    padding: 1em;
  }
}

.Header_warper__2M25- {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* box-shadow: 0px 0px 2.1px gray; */
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 2;
  background-color: white;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(21px) !important;
  -webkit-backdrop-filter: blur(21px) !important;
  border: 1px solid rgba(255, 255, 255, 0.3);
}
.Header_container__2ohAr,
.Header_nonMobileElement__x1nEd {
  display: flex;
  width: 100%;
  max-width: var(--layoutWidth);
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 6em;
  padding: 1em 0.5em;
}
.Header_mobileElement__2aBHN {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
}
.Header_MobileActionWarper__c_0Cg {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: unset;
  height: 100%;
  text-transform: uppercase;
}
.Header_logoAndMenuWarper__1UxrK {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
}
.Header_logoAndMenuWarper__1UxrK img {
  cursor: pointer;
}
.Header_menuIconBtn__1K8qL {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 1em;
  width: 1.25em;
  /* background-color: red; */
  position: relative;
  cursor: pointer;
  transition: ease-in-out 180ms;
  margin-right: 0.5em;
}
.Header_menuIconBars__3BD9G {
  width: 100%;
  height: 0.16em;
  border-radius: 1em;
  background-color: rgb(80, 80, 80);
  position: absolute;
  transition: ease-in-out 180ms;
}
.Header_menuIconBars__3BD9G:nth-child(1) {
  width: 100% !important;
  top: 0px;
}
.Header_menuIconBars__3BD9G:nth-child(3) {
  width: 100% !important;
  bottom: 0px;
}
.Header_menuIconBtn__1K8qL:hover .Header_menuIconBars__3BD9G {
  width: 60%;
}
.Header_menuIconBtn__1K8qL.Header_active__2r-Ab .Header_menuIconBars__3BD9G:nth-child(2) {
  width: 0px;
}
.Header_menuIconBtn__1K8qL.Header_active__2r-Ab .Header_menuIconBars__3BD9G:nth-child(1) {
  display: block;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  top: unset;
}
.Header_menuIconBtn__1K8qL.Header_active__2r-Ab .Header_menuIconBars__3BD9G:nth-child(3) {
  display: block;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  bottom: unset;
}
.Header_mobileOnlyElement__3srP6 {
  display: none;
  flex-direction: row;
  justify-content: center;
  grid-gap: 1em;
  gap: 1em;
  align-items: center;
}

/* buy car menus */
.Header_BuyCarSection__z9uX_ {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-start;
}
.Header_BuyCarTypes__HVfCM {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.Header_BuyCarOptions__1cWrs {
  padding: 0.8em 2.5em;
  white-space: nowrap;
  width: 100%;
  margin-right: 2.5em;
}
.Header_BuyCarOptions__1cWrs:hover {
  background-color: rgb(238, 238, 238);
}
.Header_BuyCarOptions__1cWrs:first-child {
  background-color: rgb(225, 225, 225);
  font-size: 0.7em;
  padding: 1em 2.5em;
  padding-left: 3.6em;
  opacity: 0.6;
  text-transform: uppercase;
}

.Header_wishListCount__26hxT {
  position: relative;
}
.Header_wishListCount__26hxT::before {
  content: attr(data-count);
  position: absolute;
  top: -25%;
  right: -25%;
  background-color: var(--colorD);
  color: white;
  font-size: 0.8em;
  height: 1.28em;
  width: 1.28em;
  padding: 0.1em;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1em;
}

/* more menus */
.Header_MoreMenus__fnGMI {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.Header_MoreMenusItems__1O7c1 {
  width: 100%;
  padding: 1em 2em;
  white-space: nowrap;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 1em;
  gap: 1em;
  cursor: pointer;
}
.Header_MoreMenusItems__1O7c1 .Header_icon__239wE {
  color: rgb(175, 175, 175);
}
.Header_MoreMenusItems__1O7c1 b {
  color: var(--colorAA);
}
.Header_MoreMenusItems__1O7c1:hover,
.Header_MoreMenusItems__1O7c1:hover .Header_icon__239wE {
  background-color: rgb(238, 238, 238);
  color: var(--colorAA);
}
.Header_contacts__KoO70 {
  white-space: nowrap;
  cursor: pointer;
}
.Header_contacts__KoO70 * {
  cursor: pointer;
}

.Header_sideMenuScroll__bbrxN * {
  cursor: pointer;
}

.Header_serviceLinks__3TqBL {
  width: 100%;
  max-width: var(--layoutWidth);
  display: flex;
}
@media screen and (max-width: 1051px) {
  .Header_serviceLinks__3TqBL {
    display: none;
  }
  .Header_container__2ohAr {
    height: unset;
    padding: 0.5em;
    z-index: 0;
  }
  .Header_nonMobileElement__x1nEd {
    display: none;
  }
  .Header_mobileElement__2aBHN {
    width: 100%;
    flex-direction: column;
  }
  .Header_mobileOnlyElement__3srP6 {
    display: flex;
  }
  .Header_MobileActionWarper__c_0Cg {
    justify-content: space-between;
    width: 100%;
    height: 2.5em;
  }
}

.Signin_container__2e6yd {
  width: 25em;
}
.Signin_container__2e6yd form {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.Signin_title__151wq {
  position: relative;
  padding: 0.25em 0em;
  margin: 0.5em 0em;
  text-transform: capitalize;
}
.Signin_title__151wq::after {
  content: "";
  position: absolute;
  height: 0.1em;
  border-radius: 1em;
  width: 80%;
  bottom: 0;
  left: 0;
  background-color: var(--colorA);
}
@media screen and (max-width: 860px) {
  .Signin_container__2e6yd {
    width: 80%;
  }
}

.SellCarForm_container__10_hI {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: var(--layoutWidth);
  position: relative;
}
.SellCarForm_container__10_hI form {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
}
.SellCarForm_inputWarper__1zhq3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1em;
  gap: 1em;
  justify-content: center;
  width: 100%;
}
.SellCarForm_inputWarper__1zhq3 label {
  justify-self: center;
}
.SellCarForm_inputWarper__1zhq3 label input {
  padding: 1.6em;
}
.SellCarForm_inputClassName__161AO {
  background-color: rgb(248, 248, 248);
  box-shadow: inset 2.5px 2.5px 2.5px rgb(238, 238, 238),
    inset -2.5px -2.5px 2.5px rgb(252, 252, 252);
  border-radius: 0.5em;
  border: 2px solid transparent;
  border-bottom-color: rgb(255, 255, 255);
  border-right-color: rgb(255, 255, 255);
  border-top-color: rgb(235, 235, 235);
  border-left-color: rgb(235, 235, 235);
}
.SellCarForm_btnWarper__3QGlV {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 2.5em;
}
.SellCarForm_LoadingProgress__3zurq {
  background-color: var(--colorB);
  height: 2.8em;
  display: flex;
  justify-content: center;
  border-radius: 1em;
  font-weight: 800;
  color: var(--colorA);
  align-items: center;
  padding: 1em;
  bottom: 0px;
  margin: 1em;
}
@media screen and (max-width: 1051px) {
  .SellCarForm_inputWarper__1zhq3 {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 660px) {
  .SellCarForm_inputWarper__1zhq3 {
    grid-template-columns: repeat(1, 85%);
  }
  .SellCarForm_btnWarper__3QGlV button {
    font-size: 0.6em;
    padding: 0.5em 2em;
  }
}

.AccountForm_container__2U9k8 {
  width: 50%;
}
.AccountForm_inputClassName__CkhvU {
  background-color: transparent;
  box-shadow: inset 2.5px 2.5px 2.5px rgb(238, 238, 238),
    inset -2.5px -2.5px 2.5px rgb(252, 252, 252);
  border-radius: 0.5em;
  border: 2px solid transparent;
  border-bottom-color: rgb(255, 255, 255);
  border-right-color: rgb(255, 255, 255);
  border-top-color: rgb(235, 235, 235);
  border-left-color: rgb(235, 235, 235);
  transition: all 250ms;
}
/* .inputClassName:hover {
  border: 2px solid var(--colorB);
} */

@media screen and (max-width: 860px) {
  .AccountForm_container__2U9k8 {
    width: 100%;
  }
}

.SupportForm_container__1o8zc {
  height: 100%;
}
.SupportForm_container__1o8zc form {
  font-weight: 800;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.SupportForm_title__1v_pH {
  color: var(--colorC);
}
.SupportForm_inputClassName__21PtU {
  background-color: var(--lightPrime);
}

.BrandCard_container__2CE8c {
  border-radius: 0.5em;
  background-color: #f2f3f5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 11em;
  width: 11em;
  margin: 1em;
  padding: 1em 1em 0em 1em;
  transition: ease-in-out 300ms;
  cursor: pointer;
}
.BrandCard_image__SLM2Q {
  width: 100%;
  height: auto;
}
.BrandCard_name__26JUC {
  color: var(--colorC);
  text-transform: capitalize;
  transition: ease-in-out 300ms;
  text-align: center;
  cursor: pointer;
}
.BrandCard_count__3WWx8 {
  visibility: hidden;
  color: var(--colorA);
  text-transform: uppercase;
  font-size: 0.8em;
  transition: ease-in-out 300ms;
  cursor: pointer;
}
.BrandCard_container__2CE8c:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
  box-shadow: 0px 0px 5px #bfc7d4;
}
.BrandCard_container__2CE8c:hover .BrandCard_name__26JUC {
  color: var(--colorA);
}
.BrandCard_container__2CE8c:hover .BrandCard_count__3WWx8 {
  visibility: visible;
}

@media screen and (max-width: 860px) {
  .BrandCard_container__2CE8c {
    height: 7em;
    width: 7em;
    margin: 1em;
    padding: 1em 1em 0em 1em;
  }
  .BrandCard_name__26JUC {
    font-size: 0.7em;
  }
  .BrandCard_count__3WWx8 {
    font-size: 0.5em;
  }
}

.LifeStyleCard_container__3JCpO {
  height: 16em;
  width: 25em;
  margin: 1em;
  border-radius: 1em;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 1em;
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
  cursor: pointer;
  transition: ease-in-out 250ms;
}
.LifeStyleCard_container__3JCpO::before {
  content: "";
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  background: linear-gradient(to top, #0b005aa2, transparent);
}
.LifeStyleCard_title__2UWbg,
.LifeStyleCard_about__1-1wD {
  color: white;
  z-index: 1;
}
.LifeStyleCard_container__3JCpO:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

@media screen and (max-width: 860px) {
  .LifeStyleCard_container__3JCpO {
    height: 12em;
    width: 10em;
    margin: 0.4em;
    border-radius: 1em;
    padding: 0.5em;
  }
  .LifeStyleCard_title__2UWbg {
    font-size: 0.8em;
    font-weight: 800;
  }
  .LifeStyleCard_about__1-1wD {
    font-size: 0.6em;
  }
}

.ProductCard_mobileContainer__4juxr,
.ProductCard_container__mNdM9 {
  height: 18em;
  width: 18em;
  border-radius: 1em;
  overflow: hidden;
  transition: 250ms ease-in-out;
  outline: 1px solid var(--fadeBlack);
  cursor: pointer;
  background-color: white;
}
.ProductCard_mobileContainer__4juxr {
  height: 8em;
  width: 100%;
  max-width: 25em;
  display: flex;
  flex-direction: row;
}
.ProductCard_mobileContainer__4juxr:hover,
.ProductCard_mobileContainer__4juxr:active,
.ProductCard_container__mNdM9:hover,
.ProductCard_container__mNdM9:active {
  -webkit-transform: scale(1.025);
          transform: scale(1.025);
  box-shadow: 0px 0px 11px var(--fadeBlack);
}
.ProductCard_mobileImageSection__3aY4N,
.ProductCard_ImageSection__2kqUE,
.ProductCard_mobileContentSection__2CRTA,
.ProductCard_contentSection__1z_6b {
  width: 100%;
  cursor: pointer;
}
.ProductCard_mobileImageSection__3aY4N,
.ProductCard_ImageSection__2kqUE {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.ProductCard_carSkeletonImg__2XXu5 {
  height: 100%;
  max-width: 80%;
  width: auto;
}
.ProductCard_mobileImageSection__3aY4N {
  height: 100%;
  width: 40%;
}
.ProductCard_mobileContentSection__2CRTA,
.ProductCard_contentSection__1z_6b {
  padding: 0.5em;
  padding-top: 0.5em;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 50%;
  align-items: flex-start;
  color: var(--colorAA);
}
.ProductCard_mobileContentSection__2CRTA {
  height: 100%;
  width: 60%;
}
.ProductCard_title__1mjEV {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-transform: capitalize;
  font-size: 1em;
  cursor: pointer;
}
.ProductCard_wishlistIcon__2_4s5 {
  color: var(--colorAA);
}
.ProductCard_wishlistIcon__2_4s5.ProductCard_active__2-9Hg {
  color: var(--colorD);
  -webkit-animation: ProductCard_addToWishlist__hKptz ease-in-out 250ms;
          animation: ProductCard_addToWishlist__hKptz ease-in-out 250ms;
}
@-webkit-keyframes ProductCard_addToWishlist__hKptz {
  from {
    -webkit-transform: scale(0.7);
            transform: scale(0.7);
  }
  to {
    -webkit-transform: scale(1.25);
            transform: scale(1.25);
  }
}
@keyframes ProductCard_addToWishlist__hKptz {
  from {
    -webkit-transform: scale(0.7);
            transform: scale(0.7);
  }
  to {
    -webkit-transform: scale(1.25);
            transform: scale(1.25);
  }
}
.ProductCard_metaInfo__3OR51 {
  font-size: 0.8em;
  text-transform: capitalize;
}
.ProductCard_etcInfo__32jGx {
  color: var(--fadeBlack);
  display: flex;
  padding: 0.5em 0em;
  width: 100%;
  flex-direction: column;
  border-top: 1px solid var(--fadeBlack);
}
.ProductCard_etcInfo__32jGx .ProductCard_icon__3GvGI {
  font-size: 1.6em;
}
.ProductCard_etcInfo__32jGx .ProductCard_items__2S0oy {
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-gap: 0.5em;
  gap: 0.5em;
  font-size: 0.7em;
  text-transform: capitalize;
}

/* image slider Section */
.ProductCard_ImageSliderWarper__CEwHt {
  width: 100%;
}
.ProductCard_ImageSliderWarper__CEwHt div {
  width: 100%;
}
.ProductCard_ImageSliderWarper__CEwHt div div:last-child {
  justify-content: center;
}
.ProductCard_ImageSliderWarper__CEwHt div div:last-child div {
  width: 0.8em;
  height: 0.1em;
  border-radius: 0px;
}
.ProductCard_carTypeBatch__6PykW {
  padding: 0.1em 0.5em;
  border-radius: 0.25em;
  text-transform: uppercase;
  font-size: 0.8em;
  cursor: pointer;
}
.ProductCard_carTypeBatch__6PykW.ProductCard_new__dwZa5 {
  border: 2px dotted var(--colorD);
  color: var(--colorD);
}
.ProductCard_carTypeBatch__6PykW.ProductCard_used__38_B9 {
  border: 2px dotted var(--colorA);
  color: var(--colorA);
}

@media screen and (max-width: 860px) {
  .ProductCard_container__mNdM9 {
    height: 14em;
    width: 14em;
    border-radius: 1em;
  }
  .ProductCard_metaInfo__3OR51 {
    font-size: 0.7em;
  }
}

.Section_container__3l8dd {
  width: 100%;
  max-width: var(--layoutWidth);
  padding: 2em 0em;
}
.Section_header__3D8R2 {
  text-transform: capitalize;
  color: var(--colorA);
  width: 100%;
  text-align: center;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
}
.Section_header__3D8R2::before {
  content: "";
  height: 0.08em;
  background: linear-gradient(to right, var(--colorA), transparent);
  width: 50%;
  left: 0px;
  margin-right: 1em;
}
.Section_header__3D8R2::after {
  content: "";
  height: 0.08em;
  background: linear-gradient(to left, var(--colorA), transparent);
  width: 50%;
  right: 0px;
  margin-left: 1em;
}
.Section_ContentWarper__1_qFz {
  padding: 2em 0em;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
@media screen and (max-width: 860px) {
  .Section_header__3D8R2 {
    font-size: 1em;
  }
}

.ProfileCard_container__2jj-f {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  min-height: 5em;
  border-radius: 0.1em;
  background-color: transparent;
  box-shadow: 8px 8px 5px rgb(238, 238, 238), -8px -8px 5px rgb(252, 252, 252);
  border-radius: 1.25em;
  border: 2px solid transparent;
  border-bottom-color: rgb(252, 252, 252);
  border-right-color: rgb(252, 252, 252);
  border-top-color: rgb(238, 238, 238);
  border-left-color: rgb(238, 238, 238);
  padding: 0.5em;
  grid-gap: 0.5em;
  gap: 0.5em;
}
.ProfileCard_avatar__16i4N {
  min-height: 5em;
  min-width: 5em;
  border-radius: 50%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border: 2px solid var(--colorA);
}
.ProfileCard_info__3L0Ss {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
}
.ProfileCard_title__3AFeS {
  text-transform: capitalize;
  font-size: 1.25em;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media screen and (max-width: 860px) {
  .ProfileCard_container__2jj-f {
    flex-direction: column;
  }
}

.ProductCards_conrtainer__1gaQb {
  height: 21em;
  width: 18em;
  background-color: #f2f3f5;
  border-radius: 2em;
  display: flex;
  flex-direction: column;
  padding: 1em;
  grid-gap: 1em;
  gap: 1em;
}

.ProductCards_DetailsSection__2RjNm,
.ProductCards_ImageSection__3pgeB {
  height: 50%;
  width: 100%;
  background-color: white;
  border-radius: 2em;
}
.ProductCards_ImageSection__3pgeB {
  overflow: hidden;
}
.ProductCards_ImageSection__3pgeB img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.ProductCards_DetailsSection__2RjNm {
  color: var(--colorAA);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1em;
}
.ProductCards_DetailsSection__2RjNm .ProductCards_title__19NJ- {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

/* Card Two */
.ProductCards_conrtainerTwo__3Zssw {
  height: 21em;
  width: 18em;
  background-color: #f2f3f5;
  border-radius: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ProductCards_DetailsSectionTwo__32kLi,
.ProductCards_ImageSectionTwo__1GEjX {
  height: 50%;
  width: 100%;
  background-color: white;
  border-radius: 2em;
}
.ProductCards_ImageSectionTwo__1GEjX {
  overflow: hidden;
}
.ProductCards_ImageSectionTwo__1GEjX img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.ProductCards_DetailsSectionTwo__32kLi {
  color: var(--colorAA);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1em;
  width: calc(100% - 1em);
  margin: 0.5em;
}
/* Card Three */
.ProductCards_conrtainerThree__3Yh9v {
  height: 21em;
  width: 18em;
  border-radius: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.ProductCards_conrtainerThree__3Yh9v::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 60%;
  background-color: #f2f3f5;
  bottom: 0px;
  border-radius: 1em;
  z-index: -1;
}

.ProductCards_DetailsSectionThree__JNXa4,
.ProductCards_ImageSectionThree__22IBS {
  height: 50%;
  width: 100%;
  z-index: 0;
}
.ProductCards_ImageSectionThree__22IBS {
  overflow: hidden;
  border-radius: 2em;
  width: 85%;
}
.ProductCards_ImageSectionThree__22IBS img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.ProductCards_DetailsSectionThree__JNXa4 {
  color: var(--colorAA);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1em;
  width: 100%;
}

/* Card Four */

.ProductCards_conrtainerFour__kz3Os {
  height: 21em;
  width: 18em;
  background-color: #f2f3f5;
  border-radius: 2em;
  display: flex;
  flex-direction: column;
}

.ProductCards_DetailsSectionFour__3UZtb,
.ProductCards_ImageSectionFour__TGODL {
  height: 50%;
  width: 100%;
  background-color: #f2f3f5;
  border-radius: 2em;
}
.ProductCards_ImageSectionFour__TGODL {
  overflow: hidden;
}
.ProductCards_ImageSectionFour__TGODL img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.ProductCards_DetailsSectionFour__3UZtb {
  color: var(--colorAA);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1em;
}

/* everyOne */
.ProductCards_carSkeletonImg__MLd1S {
  height: 100%;
  width: 100%;
  object-fit: contain !important;
}
.ProductCards_conrtainer__1gaQb,
.ProductCards_conrtainerTwo__3Zssw,
.ProductCards_conrtainerThree__3Yh9v,
.ProductCards_conrtainerFour__kz3Os {
  transition: ease-in-out 250ms;
}
.ProductCards_conrtainer__1gaQb:hover,
.ProductCards_conrtainerTwo__3Zssw:hover,
.ProductCards_conrtainerThree__3Yh9v:hover,
.ProductCards_conrtainerFour__kz3Os:hover {
  -webkit-filter: drop-shadow(0px 0px 5px var(--fadeBlack));
          filter: drop-shadow(0px 0px 5px var(--fadeBlack));
  -webkit-transform: scale(1.025);
          transform: scale(1.025);
}

.ProductCards_info__2GTCU {
  font-size: 0.8em;
  text-transform: capitalize;
}
.ProductCards_BrandTitleWarper__ZVsef,
.ProductCards_cardTitleWarper__1tpQr {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  text-transform: capitalize;
}
.ProductCards_BrandTitleWarper__ZVsef {
  justify-content: flex-start;
  color: var(--fadeBlack);
  font-size: 0.7em;
}
.ProductCards_BrandTitleWarper__ZVsef p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ProductCards_BrandTitleWarper__ZVsef span {
  opacity: 0.6;
  font-size: 1.6em;
}

.ProductCards_wishlistIcon__15FG2 {
  color: var(--colorAA);
}
.ProductCards_wishlistIcon__15FG2.ProductCards_active___wiP- {
  color: var(--colorD);
  -webkit-animation: ProductCards_addToWishlist__2fUJA ease-in-out 250ms;
          animation: ProductCards_addToWishlist__2fUJA ease-in-out 250ms;
}
@-webkit-keyframes ProductCards_addToWishlist__2fUJA {
  from {
    -webkit-transform: scale(0.7);
            transform: scale(0.7);
  }
  to {
    -webkit-transform: scale(1.25);
            transform: scale(1.25);
  }
}
@keyframes ProductCards_addToWishlist__2fUJA {
  from {
    -webkit-transform: scale(0.7);
            transform: scale(0.7);
  }
  to {
    -webkit-transform: scale(1.25);
            transform: scale(1.25);
  }
}

@media screen and (max-width: 860px) {
  .ProductCards_conrtainer__1gaQb,
  .ProductCards_conrtainerTwo__3Zssw,
  .ProductCards_conrtainerThree__3Yh9v,
  .ProductCards_conrtainerFour__kz3Os {
    /* height: 11em;
    width: 11em; */
    font-size: 0.55em;
  }
}

.SearchResultCard_container__1kSLS {
  width: 100%;
  padding: 0.5em;
  border-radius: 0.5em;
  background-color: white;
  text-transform: capitalize;
  cursor: pointer;
}
.SearchResultCard_container__1kSLS * {
  cursor: pointer;
}
.SearchResultCard_container__1kSLS:hover {
  background-color: var(--lightDark);
}

.SellBuyCarCard_container__3aYhz {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 0.5em;
  gap: 0.5em;
  border: 1px solid var(--colorA);
  border-radius: 0.5em;
  padding: 1em 0.5em 0.5em 1em;
  width: 100%;
  margin: 1em;
  align-self: center;
  position: relative;
}
.SellBuyCarCard_cardBatchNumber__10cu2 {
  position: absolute;
  top: 2.5px;
  left: 2.5px;
  background-color: var(--colorE);
  border-radius: 2.5px;
  height: 1.25em;
  min-width: 1.25em;
  padding: 0.25em;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 0.8em;
  /* padding: 100% 100% 0px 0px; */
}

.ViewCarDetails_container__1m1cc {
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 2em;
  gap: 2em;
}
.ViewCarDetails_section__1o3BE {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 1em;
  gap: 1em;
}
.ViewCarDetails_section__1o3BE .ViewCarDetails_title__LOfkf {
  /* text-decoration: underline; */
  position: relative;
  padding-left: 0.5em;
}
.ViewCarDetails_section__1o3BE .ViewCarDetails_title__LOfkf::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 0.25em;
  left: 0px;
  border-radius: 0.25em;
  background-color: var(--colorA);
}
.ViewCarDetails_itemTitle__2PYxC {
  text-transform: capitalize;
}
.ViewCarDetails_items__1cVr5 {
  justify-self: start;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 0.5em;
  gap: 0.5em;
}
.ViewCarDetails_items__1cVr5 * {
  margin: 0px;
  padding: 0px;
  word-break: break-all;
  text-transform: capitalize;
}

.ViewCarDetails_itemsWarper__126sm {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-content: start;
  justify-items: center;
  width: 100%;
  grid-gap: 2em;
  gap: 2em;
}
@media screen and (max-width: 1151px) {
  .ViewCarDetails_itemsWarper__126sm {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 500px) {
  .ViewCarDetails_itemsWarper__126sm {
    grid-template-columns: repeat(1, 1fr);
  }
  .ViewCarDetails_section__1o3BE {
    font-size: 0.8em;
    word-break: break-all;
  }
}

.CarComparisonCard_container__Sd6yO {
  --disableCardColor: rgb(241, 241, 241);
  height: 16em;
  width: 16em;
  padding: 0.5em;
  border: 1px solid rgb(80, 80, 80);
  border-radius: 0.5em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  -webkit-filter: opacity(0.5);
          filter: opacity(0.5);
  cursor: pointer;
}
.CarComparisonCard_container__Sd6yO * {
  cursor: pointer;
}
.CarComparisonCard_addIcon__1sLYq {
  color: var(--lightDark);
  font-size: 2em;
  border-radius: 50%;
  border: 5px dotted var(--disableCardColor);
  padding: 0.5em;
}
.CarComparisonCard_BtnSkeleton__E-LrH {
  width: 80%;
  padding: 0.5em;
  border-radius: 0.5em;
  border: none;
  background-color: var(--disableCardColor);
}

@media screen and (max-width: 860px) {
  .CarComparisonCard_container__Sd6yO {
    height: 11em;
    width: 9em;
    grid-gap: 0.5em;
    gap: 0.5em;
    margin: 0.5em 0em;
  }
}

.CarSearchSection_container__kPHRx {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 1em;
  gap: 1em;
  width: 100%;
}
.CarSearchSection_searchInput__1Qm79 {
  min-width: 100%;
  padding: 0.8em;
  border: 1px solid var(--lightDark);
  width: 26em;
  border-radius: 0.25em;
}
.CarSearchSection_searchInput__1Qm79:focus {
  outline: 1px solid var(--colorA);
}
.CarSearchSection_searchItemWarper__m-fhC {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.CompareSearchCard_searchItem__jTjEG {
  border: 1px solid var(--lightDark);
  border-radius: 0.5em;
  margin: 0.5em 0em;
  width: 100%;
  height: 3em;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  box-shadow: 0px 0px 2px var(--lightDark);
  cursor: pointer;
}
.CompareSearchCard_searchItem__jTjEG * {
  cursor: pointer;
}
.CompareSearchCard_searchItemImageSection__3YDf3 {
  min-height: 100%;
  width: 5em;
  background-color: var(--colorA);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.CompareSearchCard_searchItemContent__2O4Td {
  padding: 0.5em;
  text-transform: capitalize;
}

.Inventory_container__e7K1V {
  background-color: #f8f8f8;
  padding: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 0.5em;
  box-shadow: 0px 0px 5px #a8b3ba;
}

.SearchBar_Warper__30koR {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  max-height: 2.5em;
  width: 100%;
  margin: 0.5em;
}
.SearchBar_container__2hpct {
  border-radius: 0.25em;
  border: none;
  background-color: rgb(245, 245, 245);
  padding: 0px 1em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  height: 100%;
  width: 100%;
}
.SearchBar_container__2hpct .SearchBar_input__hup1W,
.SearchBar_container__2hpct .SearchBar_icon__K8w8k {
  color: rgb(121, 121, 121);
}
.SearchBar_container__2hpct .SearchBar_input__hup1W {
  background-color: transparent;
  border: none;
  width: 80%;
  outline: none;
}
.SearchBar_input__hup1W:focus ~ .SearchBar_icon__K8w8k,
.SearchBar_input__hup1W:focus {
  color: black;
}
.SearchBar_SearchResultsWarper__1w4QR,
.SearchBar_SearchResultsWarperTwo__2zitK {
  width: 100%;
  max-height: 60vh;
  background-color: white;
  position: absolute;
  top: 2.5em;
  border-radius: 0.5em;
  z-index: 1;
  box-shadow: 0px 0px 5px var(--lightDark);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  grid-gap: 0.5em;
  gap: 0.5em;
}
.SearchBar_SearchResultsWarperTwo__2zitK {
  display: none;
  position: fixed;
  top: 1em;
}
@media screen and (max-width: 1051px) {
  .SearchBar_Warper__30koR {
    width: 100%;
    height: 2.5em;
  }
  .SearchBar_SearchResultsWarper__1w4QR {
    display: none;
  }
  .SearchBar_SearchResultsWarperTwo__2zitK {
    width: 98%;
    top: 7em;
    display: flex;
  }
}

.BottomNav_container__3jw21 {
  position: fixed;
  bottom: 0px;
  width: 100%;
  background-color: white;
  box-shadow: 0px 0px 5px rgb(221, 221, 221);
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  z-index: 2;
  display: none;
}
.BottomNav_menuItem__2pOMv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1em 0em;
  width: 100%;
  height: 100%;
  position: relative;
  cursor: pointer;
  text-transform: capitalize;
  opacity: 0.5;
  font-size: 0.6em;
}
.BottomNav_menuItem__2pOMv.BottomNav_active__21sba {
  opacity: 1;
  color: var(--colorA);
  position: relative;
}
.BottomNav_menuItem__2pOMv.BottomNav_active__21sba::before {
  content: "";
  height: 10%;
  width: 50%;
  border-radius: 0px 0px 1em 1em;
  top: 0px;
  position: absolute;
  background-color: var(--colorA);
}

.BottomNav_menuItem__2pOMv.BottomNav_active__21sba .BottomNav_wishListCount__1P_2D {
  position: relative;
}
.BottomNav_menuItem__2pOMv.BottomNav_active__21sba .BottomNav_wishListCount__1P_2D::before {
  content: attr(data-count);
  position: absolute;
  top: -25%;
  right: -25%;
  background-color: var(--colorD);
  color: white;
  font-size: 0.8em;
  height: 1.28em;
  width: 1.28em;
  padding: 0.1em;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1em;
  font-size: 1.25em;
}

@media screen and (max-width: 1051px) {
  .BottomNav_container__3jw21 {
    display: flex;
  }
}

/* menu Btn */
.SideMenu_closeBtn__3_3et {
  position: absolute;
  top: 1em;
  right: 1em;
}
.SideMenu_menuIconBtn__2yOYH {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 1.5em;
  width: 2em;
  position: relative;
  cursor: pointer;
  transition: ease-in-out 180ms;
}
.SideMenu_menuIconBars__PoRG2 {
  width: 100%;
  height: 0.25em;
  border-radius: 1em;
  background-color: rgb(255, 255, 255);
  position: absolute;
  transition: ease-in-out 180ms;
}
.SideMenu_menuIconBars__PoRG2:nth-child(1) {
  width: 100% !important;
  top: 0px;
}
.SideMenu_menuIconBars__PoRG2:nth-child(3) {
  width: 100% !important;
  bottom: 0px;
}
.SideMenu_menuIconBtn__2yOYH:hover .SideMenu_menuIconBars__PoRG2 {
  width: 60%;
}
.SideMenu_menuIconBtn__2yOYH.SideMenu_active__YuVH2 .SideMenu_menuIconBars__PoRG2:nth-child(2) {
  width: 0px;
}
.SideMenu_menuIconBtn__2yOYH.SideMenu_active__YuVH2 .SideMenu_menuIconBars__PoRG2:nth-child(1) {
  display: block;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  top: unset;
}
.SideMenu_menuIconBtn__2yOYH.SideMenu_active__YuVH2 .SideMenu_menuIconBars__PoRG2:nth-child(3) {
  display: block;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  bottom: unset;
}

/* side menu bar */
.SideMenu_SideMenu__3wpPT {
  display: none;
  min-height: 100vh;
  width: 100%;
  position: fixed;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 5;
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
}
.SideMenu_myNodeEnterActive__1b4L2 .SideMenu_container__38g_V {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
}
.SideMenu_myNodeEnterDone__fJDd9 .SideMenu_container__38g_V {
  -webkit-transform: translateX(0);
          transform: translateX(0);
  transition: -webkit-transform 300ms;
  transition: transform 300ms;
  transition: transform 300ms, -webkit-transform 300ms;
}
.SideMenu_myNodeExitActive__3JVcy .SideMenu_container__38g_V {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  transition: -webkit-transform 300ms;
  transition: transform 300ms;
  transition: transform 300ms, -webkit-transform 300ms;
}
.SideMenu_myNodeExitDone__13HuP .SideMenu_container__38g_V {
  -webkit-transform: translateX(0);
          transform: translateX(0);
}
.SideMenu_container__38g_V {
  width: 80%;
  max-width: 25em;
  background-color: white;
}
.SideMenu_header__iuhP2 {
  width: 100%;
  background-color: var(--colorAA);
  color: white;
  font-size: 0.65em;
  padding: 2.1em 1em;
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-gap: 0.5em;
  gap: 0.5em;
}
.SideMenu_menusSection__3obJt {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.SideMenu_menusItem__3lMaO {
  width: 100%;
  padding: 1em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  text-transform: capitalize;
  grid-gap: 1em;
  gap: 1em;
  cursor: pointer;
}
.SideMenu_menusSection__3obJt .SideMenu_menusItem__3lMaO {
  border-bottom: 1px solid rgb(225, 225, 225);
}
.SideMenu_menusSection__3obJt .SideMenu_menusItem__3lMaO:nth-last-child(1) {
  border: none;
}
.SideMenu_menuItemIcon__Qp14T {
  color: rgb(160, 160, 160);
}
.SideMenu_menusItemTitle__2hs_1 {
  color: var(--colorAA);
}

@media screen and (max-width: 1051px) {
  /* side menu bar */
  .SideMenu_SideMenu__3wpPT {
    display: flex;
  }
}

.BodyType_container__3oca- {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.BodyType_carTypeHolder__1HIbV {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f2f3f5;
  border-radius: 0.5em;
  margin: 1em;
  padding: 1em 1.25em;
  cursor: pointer;
  transition: 250ms ease-in-out;
  grid-gap: 0.5em;
  gap: 0.5em;
}
.BodyType_carTypeHolder__1HIbV svg {
  height: 2.1em;
  width: auto;
}
.BodyType_title__2ECi3 {
  font-size: 0.8em;
  cursor: pointer;
}
.BodyType_carTypeHolder__1HIbV:hover {
  outline: 1px solid var(--colorA);
}
.BodyType_carTypeHolder__1HIbV.BodyType_active__30c07 {
  color: var(--colorA);
  outline: 1px solid var(--colorA);
  font-weight: 600;
  -webkit-transform: translateY(-5%);
          transform: translateY(-5%);
}
.BodyType_viewMoreBtn__3sSXg {
  color: var(--colorA);
  text-transform: capitalize;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: 250ms ease-in-out;
}
.BodyType_viewMoreTitle__1XE5x {
  opacity: 0.85;
  cursor: pointer;
}
.BodyType_viewMoreTitle__1XE5x:hover,
.BodyType_viewMoreTitle__1XE5x:active {
  text-decoration: underline;
  opacity: 1;
}
@media screen and (max-width: 860px) {
  .BodyType_carTypeHolder__1HIbV {
    border-radius: 0.5em;
    margin: 0.5em;
    padding: 0.8em 1em;
    grid-gap: 0.5em;
    gap: 0.5em;
  }
  .BodyType_carTypeHolder__1HIbV svg {
    height: 2.1em;
  }
}

.FeaturedSection_container__RlqHT {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.FeaturedSection_carTypeHolder__1JM3H {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f2f3f5;
  border-radius: 0.5em;
  margin: 1em;
  padding: 0.8em 1.5em;
  cursor: pointer;
  transition: 250ms ease-in-out;
  grid-gap: 0.5em;
  gap: 0.5em;
}
.FeaturedSection_title__VZ4BT {
  font-size: 0.8em;
  cursor: pointer;
}
.FeaturedSection_carTypeHolder__1JM3H:hover {
  outline: 1px solid var(--colorA);
}
.FeaturedSection_carTypeHolder__1JM3H.FeaturedSection_active__abOuE {
  color: var(--colorA);
  border: 1px solid var(--colorA);
  font-weight: 700;
}
.FeaturedSection_viewMoreBtn__1gJIL {
  color: var(--colorA);
  text-transform: capitalize;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: 250ms ease-in-out;
}
.FeaturedSection_viewMoreTitle__1uWoS {
  opacity: 0.85;
  cursor: pointer;
}
.FeaturedSection_viewMoreTitle__1uWoS:hover,
.FeaturedSection_viewMoreTitle__1uWoS:active {
  text-decoration: underline;
  opacity: 1;
}
@media screen and (max-width: 860px) {
  .FeaturedSection_carTypeHolder__1JM3H {
    border-radius: 0.5em;
    margin: 0.5em;
    padding: 0.8em 1em;
    grid-gap: 0.5em;
    gap: 0.5em;
  }
}

.NotFound_container__36qKf {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  min-height: 100vh;
}
.NotFound_imageSection__vAKos {
  height: 60vh;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.Account_warper__19qbf {
  width: 100%;
  background-color: rgb(245, 245, 245);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.Account_container__2KXju {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  max-width: var(--layoutWidth);
  min-height: 60vh;
  padding: 2.5em 1em;
  padding-bottom: 8em;
  grid-gap: 2.5em;
  gap: 2.5em;
}
.Account_AccountMenuSectionWarper__ceAxj,
.Account_AccountDetailViewSectionWarper__3g-BJ {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Account_AccountMenuSectionWarper__ceAxj {
  width: 30%;
}
.Account_AccountDetailViewSectionWarper__3g-BJ {
  width: 70%;
}

@media screen and (max-width: 860px) {
  .Account_container__2KXju {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
  }
  .Account_AccountMenuSectionWarper__ceAxj,
  .Account_AccountDetailViewSectionWarper__3g-BJ {
    width: 100%;
  }
}

.AccountMenuSection_container__sk4DJ {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 0.5em;
  gap: 0.5em;
  width: 100%;
  max-width: 28em;
  grid-gap: 2.8em;
  gap: 2.8em;
}
.AccountMenuSection_menuItem__12zwG {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.1em;
  background-color: transparent;
  box-shadow: 11px 11px 11px rgb(232, 232, 232),
    -11px -11px 11px rgb(255, 255, 255);
  border-radius: 1.25em;
  border: 2px solid transparent;
  border-bottom-color: rgb(255, 255, 255);
  border-right-color: rgb(255, 255, 255);
  border-top-color: rgb(232, 232, 232);
  border-left-color: rgb(232, 232, 232);
  padding: 1em 1em;
  cursor: pointer;
  -webkit-columns: var(--colorAA);
          columns: var(--colorAA);
  font-weight: 500;
}
.AccountMenuSection_menuItem__12zwG.AccountMenuSection_active__1B6DV {
  border-color: var(--colorA) !important;
}
.AccountMenuSection_menuItem__12zwG:hover,
.AccountMenuSection_menuItem__12zwG:active {
  box-shadow: 11px 11px 11px rgb(232, 232, 232),
    -11px -11px 11px rgb(255, 255, 255), inset 8px 8px 8px rgb(235, 235, 235),
    inset -8px -8px 11px rgb(255, 255, 255);
  border-top-color: rgb(252, 252, 252);
  border-left-color: rgb(252, 252, 252);
  border-bottom-color: rgb(238, 238, 238);
  border-right-color: rgb(238, 238, 238);
}
.AccountMenuSection_Icon__25Lqm {
  color: var(--colorA);
  transition: all 250ms;
}
.AccountMenuSection_Icon__25Lqm.AccountMenuSection_active__1B6DV {
  color: var(--colorA);
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}

.AccountDetailViewSection_warper__2btrO {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  grid-gap: 1em;
  gap: 1em;
}
.AccountDetailViewSection_container__3rN4m {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  /* height: 80vh; */
  padding: 1em;
  border-radius: 1em;
  box-shadow: inset 8px 8px 5px rgb(238, 238, 238),
    inset -8px -8px 5px rgb(252, 252, 252);
  border-top-color: rgb(252, 252, 252);
  border-left-color: rgb(252, 252, 252);
  border-bottom-color: rgb(238, 238, 238);
  border-right-color: rgb(238, 238, 238);
}

.Wishlist_container__1p6g_ {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-items: center;
  grid-gap: 1em;
  gap: 1em;
}
.Wishlist_cardWarper__2z4uy {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--lightDark);
  width: 100%;
}

@media screen and (max-width: 1051px) {
  .Wishlist_container__1p6g_ {
    grid-template-columns: repeat(1, 1fr);
  }
}

.WishlistCard_container__3vN2l {
  height: 11em;
  width: 100%;
  max-width: 28em;
  display: flex;
  flex-direction: row;
  border-radius: 1em;
  overflow: hidden;
  transition: 250ms ease-in-out;
  outline: 1px solid var(--fadeBlack);
  cursor: pointer;
  background-color: white;
}
.WishlistCard_container__3vN2l:hover,
.WishlistCard_container__3vN2l:active {
  -webkit-transform: scale(1.025);
          transform: scale(1.025);
  box-shadow: 0px 0px 11px var(--fadeBlack);
}
.WishlistCard_ImageSection__2In4w,
.WishlistCard_contentSection__2twTs {
  width: 100%;
  cursor: pointer;
}
.WishlistCard_ImageSection__2In4w {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.WishlistCard_carSkeletonImg__RtFv6 {
  height: 100%;
  max-width: 80%;
  width: auto;
}
.WishlistCard_contentSection__2twTs {
  padding: 0.5em;
  padding-top: 0.5em;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
  width: 50%;
  align-items: flex-start;
  color: var(--colorAA);
}
.WishlistCard_title__hvu7H {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-transform: capitalize;
  font-size: 1em;
  cursor: pointer;
}
.WishlistCard_wishlistIcon__iqplL {
  color: var(--colorAA);
}
.WishlistCard_wishlistIcon__iqplL.WishlistCard_active__1_A5k {
  color: var(--colorD);
  -webkit-animation: WishlistCard_addToWishlist__2WmgG ease-in-out 250ms;
          animation: WishlistCard_addToWishlist__2WmgG ease-in-out 250ms;
}
@-webkit-keyframes WishlistCard_addToWishlist__2WmgG {
  from {
    -webkit-transform: scale(0.7);
            transform: scale(0.7);
  }
  to {
    -webkit-transform: scale(1.25);
            transform: scale(1.25);
  }
}
@keyframes WishlistCard_addToWishlist__2WmgG {
  from {
    -webkit-transform: scale(0.7);
            transform: scale(0.7);
  }
  to {
    -webkit-transform: scale(1.25);
            transform: scale(1.25);
  }
}
.WishlistCard_metaInfo__3iRNc {
  font-size: 0.8em;
  text-transform: capitalize;
}
.WishlistCard_etcInfo__WOUmN {
  color: var(--fadeBlack);
  display: flex;
  padding: 0.5em 0em;
  width: 100%;
  flex-direction: column;
  border-top: 1px solid var(--fadeBlack);
}
.WishlistCard_etcInfo__WOUmN .WishlistCard_icon__2e8Sh {
  font-size: 1.6em;
}
.WishlistCard_etcInfo__WOUmN .WishlistCard_items__1DSv1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-gap: 0.5em;
  gap: 0.5em;
  font-size: 0.7em;
  text-transform: capitalize;
}
.WishlistCard_bookBtn__3n5yE {
  margin: 0px;
  color: var(--colorA);
  border-color: var(--colorA);
  width: 100%;
}

.WishlistCard_carTypeBatch__2RxZq {
  padding: 0.1em 0.5em;
  border-radius: 0.25em;
  text-transform: uppercase;
  font-size: 0.8em;
  cursor: pointer;
}
.WishlistCard_carTypeBatch__2RxZq.WishlistCard_new__2e7Fo {
  border: 2px dotted var(--colorD);
  color: var(--colorD);
}
.WishlistCard_carTypeBatch__2RxZq.WishlistCard_used__9JYCh {
  border: 2px dotted var(--colorA);
  color: var(--colorA);
}

@media screen and (max-width: 860px) {
  .WishlistCard_container__3vN2l {
    height: 8em;
  }
  .WishlistCard_metaInfo__3iRNc {
    font-size: 0.7em;
  }
}

.Bookings_DetailsPanel__1sNdw {
  width: 32%;
  padding: 1em;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 0.5em;
  gap: 0.5em;
  border: 1px solid var(--lightDark);
  border-radius: 0.25em;
  text-transform: capitalize;
}
.Bookings_DetailsPanel__1sNdw .Bookings_title__18yKg {
  color: var(--colorA);
}
.Bookings_detailBookingInfo__26fro {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-self: center;
  width: 100%;
  grid-gap: 0.5em;
  gap: 0.5em;
  margin: 0.5em;
}
@media screen and (max-width: 860px) {
  .Bookings_DetailsPanel__1sNdw {
    width: 100%;
  }
}

.Dashboard_container__-Gb4t {
  /* background-color: white; */
  /* box-shadow: 0px 0px 5px var(--fadeBlack); */
  width: 100%;
  border-radius: 0.5em;
  /* padding: 1em; */
  display: flex;
  flex-direction: column;
  grid-gap: 2em;
  gap: 2em;
}

.BuySellCars_container__254Tf {
  width: 100%;
  padding: 1em 0em;
  border-radius: 1em;
  background-color: white;
  padding: 2em;
  border-radius: 1em;
  -webkit-filter: drop-shadow(0px 0px 5px var(--lightDark));
          filter: drop-shadow(0px 0px 5px var(--lightDark));
}

.CardSection_container__8Dm4n {
  width: 100%;
  display: flex;
  flex-direction: row;
  /* flex-wrap: wrap; */
  align-items: center;
  grid-gap: 1em;
  gap: 1em;
  justify-content: space-between;
  background-color: white;
  border-radius: 1em;
  padding: 2em;
  -webkit-filter: drop-shadow(0px 0px 5px var(--lightDark));
          filter: drop-shadow(0px 0px 5px var(--lightDark));
}
.CardSection_statusContainer__i4tHd {
  width: 100%;
  display: flex;
  flex-direction: row;
  /* flex-wrap: wrap; */
  align-items: center;
  grid-gap: 3.5em;
  gap: 3.5em;
  justify-content: center;
  background-color: white;
  border-radius: 1em;
  padding: 2em;
  -webkit-filter: drop-shadow(0px 0px 5px var(--lightDark));
          filter: drop-shadow(0px 0px 5px var(--lightDark));
}

.TotalNumberCard_container__mYd5J {
  border-radius: 1em;
  padding: 1em;
  background: linear-gradient(to bottom, #daf1ff, #d6eaf7);
  /* background-color: ; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transition: ease-in-out 250ms;
  width: 16em;
  grid-gap: 0.5em;
  gap: 0.5em;
}

.TotalNumberCard_container__mYd5J span {
  background-color: var(--colorC);
  border-radius: 0.5em;
  padding: 0.5em;
}
.TotalNumberCard_container__mYd5J:hover {
  box-shadow: 0px 0px 11px #e3f3fd;
  /* filter: drop-shadow(0px 5px 0px #99c5df); */
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px);
}

.FinancePage_Warper__3L81C {
  width: 100%;
  background-color: var(--lightDark);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.FinancePage_container__1en25 {
  width: 100%;
  max-width: var(--layoutWidth);
  display: flex;
  flex-direction: row;
  background-color: var(--lightDark);
}
.FinancePage_imageSection__34DQJ {
  width: 60%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
}
.FinancePage_title__qDBIv {
  color: white;
  font-size: 3em;
  text-shadow: 0px 0px 25px rgba(0, 0, 0, 0.8);
  text-align: center;
  margin-top: 1em;
}
.FinancePage_formSection__39sO- {
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2em 0px 2em 0px;
}
.FinancePage_formWarper__3Lt6I {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding: 1em;
  border-radius: 0.5em;
  -webkit-transform: translateX(-25%);
          transform: translateX(-25%);
}
.FinancePage_formTitle__28qt7 {
  display: none;
}

@media screen and (max-width: 720px) {
  .FinancePage_container__1en25 {
    background-color: white;
  }
  .FinancePage_imageSection__34DQJ {
    display: none;
  }
  .FinancePage_formSection__39sO- {
    width: 100%;
  }
  .FinancePage_formTitle__28qt7 {
    display: block;
  }
  .FinancePage_formWarper__3Lt6I {
    padding: 0em;
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    flex-direction: column;
  }
}

