.container {
  border-radius: 1em;
  padding: 1em;
  background: linear-gradient(to bottom, #daf1ff, #d6eaf7);
  /* background-color: ; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transition: ease-in-out 250ms;
  width: 16em;
  gap: 0.5em;
}

.container span {
  background-color: var(--colorC);
  border-radius: 0.5em;
  padding: 0.5em;
}
.container:hover {
  box-shadow: 0px 0px 11px #e3f3fd;
  /* filter: drop-shadow(0px 5px 0px #99c5df); */
  transform: translateY(-5px);
}
