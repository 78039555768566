.mobileContainer,
.container {
  height: 18em;
  width: 18em;
  border-radius: 1em;
  overflow: hidden;
  transition: 250ms ease-in-out;
  outline: 1px solid var(--fadeBlack);
  cursor: pointer;
  background-color: white;
}
.mobileContainer {
  height: 8em;
  width: 100%;
  max-width: 25em;
  display: flex;
  flex-direction: row;
}
.mobileContainer:hover,
.mobileContainer:active,
.container:hover,
.container:active {
  transform: scale(1.025);
  box-shadow: 0px 0px 11px var(--fadeBlack);
}
.mobileImageSection,
.ImageSection,
.mobileContentSection,
.contentSection {
  width: 100%;
  cursor: pointer;
}
.mobileImageSection,
.ImageSection {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.carSkeletonImg {
  height: 100%;
  max-width: 80%;
  width: auto;
}
.mobileImageSection {
  height: 100%;
  width: 40%;
}
.mobileContentSection,
.contentSection {
  padding: 0.5em;
  padding-top: 0.5em;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 50%;
  align-items: flex-start;
  color: var(--colorAA);
}
.mobileContentSection {
  height: 100%;
  width: 60%;
}
.title {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-transform: capitalize;
  font-size: 1em;
  cursor: pointer;
}
.wishlistIcon {
  color: var(--colorAA);
}
.wishlistIcon.active {
  color: var(--colorD);
  animation: addToWishlist ease-in-out 250ms;
}
@keyframes addToWishlist {
  from {
    transform: scale(0.7);
  }
  to {
    transform: scale(1.25);
  }
}
.metaInfo {
  font-size: 0.8em;
  text-transform: capitalize;
}
.etcInfo {
  color: var(--fadeBlack);
  display: flex;
  padding: 0.5em 0em;
  width: 100%;
  flex-direction: column;
  border-top: 1px solid var(--fadeBlack);
}
.etcInfo .icon {
  font-size: 1.6em;
}
.etcInfo .items {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5em;
  font-size: 0.7em;
  text-transform: capitalize;
}

/* image slider Section */
.ImageSliderWarper {
  width: 100%;
}
.ImageSliderWarper div {
  width: 100%;
}
.ImageSliderWarper div div:last-child {
  justify-content: center;
}
.ImageSliderWarper div div:last-child div {
  width: 0.8em;
  height: 0.1em;
  border-radius: 0px;
}
.carTypeBatch {
  padding: 0.1em 0.5em;
  border-radius: 0.25em;
  text-transform: uppercase;
  font-size: 0.8em;
  cursor: pointer;
}
.carTypeBatch.new {
  border: 2px dotted var(--colorD);
  color: var(--colorD);
}
.carTypeBatch.used {
  border: 2px dotted var(--colorA);
  color: var(--colorA);
}

@media screen and (max-width: 860px) {
  .container {
    height: 14em;
    width: 14em;
    border-radius: 1em;
  }
  .metaInfo {
    font-size: 0.7em;
  }
}
