.container {
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin: 2.5em;
  z-index: 1;
}
.container::before {
  content: "";
  position: absolute;
  height: 11%;
  width: 100%;
  background-color: var(--colorB);
  transition: all 500ms;
}

.progressPoint {
  border: 2.5px solid var(--colorA);
  border-radius: 0.5em;
  height: 1.75em;
  width: 1.75em;
  color: white;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: black;
  background-color: white;
}
.progressPoint.active {
  background-color: var(--colorA);
  color: white;
  font-weight: 800;
}
.progressPoint.pointActive {
  background-color: var(--colorA);
  color: white;
}
.progressPoint.pointActive::after {
  content: "";
  height: 0.5em;
  width: 0.5em;
  border-radius: 2.5px;
  border: 2.5px solid var(--colorA);
  border-top-color: transparent;
  border-left-color: transparent;
  position: absolute;
  top: -60%;
  transform: rotate(45deg);
}
.progress {
  position: absolute;
  height: 21%;
  width: 0%;
  border-radius: 1em;
  background-color: var(--colorA);
  transition: all 500ms;
}
