.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.titleWarper {
  background-color: var(--colorD);
  padding: 0.8em;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 1em;
  width: 100%;
  align-items: center;
}
.TalkIcon {
  border-radius: 50%;
  padding: 0.25em;
  background-color: white;
  color: var(--colorD);
}
.ContactLink {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 0.8em;
  width: 100%;
  color: white;
  padding: 0.8em;
}
.contactIcon {
  color: var(--colorA);
  font-size: 2em;
}
