.container {
  width: 25em;
}
.container form {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.title {
  position: relative;
  padding: 0.25em 0em;
  margin: 0.5em 0em;
  text-transform: capitalize;
}
.title::after {
  content: "";
  position: absolute;
  height: 0.1em;
  border-radius: 1em;
  width: 80%;
  bottom: 0;
  left: 0;
  background-color: var(--colorA);
}
@media screen and (max-width: 860px) {
  .container {
    width: 80%;
  }
}
