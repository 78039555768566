.Slider {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: 0;
}
.SlideWarper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: none;
  scroll-behavior: smooth;
  width: 100vw;
  scroll-snap-type: mandatory;
  scroll-snap-points-x: repeat(100%);
  scroll-snap-type: x mandatory;
}
.SlideWarper::-webkit-scrollbar {
  display: none;
}
.ScrollElement {
  min-width: 100vw;
  max-width: 100vw;
}
.leftArrow,
.rightArrow {
  visibility: hidden;
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 33%;
  padding: 0.5em;
  background-color: white;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  transition: ease-in-out 100ms;
}
.leftArrow {
  transform: translateX(-100%);
}
.rightArrow {
  transform: translateX(100%);
}
.Slider:hover .leftArrow {
  visibility: visible;
  transform: translateX(0%);
}
.Slider:hover .rightArrow {
  visibility: visible;
  transform: translateX(0%);
}
.leftArrow {
  left: 0px;
  border-radius: 0em 0.25em 0.25em 0em;
}
.rightArrow {
  right: 0px;
  border-radius: 0.25em 0em 0em 0.25em;
}
.DotsWarper {
  padding: 0.5em;
  position: absolute;
  bottom: 0px;
  display: flex;
  flex-direction: row;
  gap: 0.8em;
}
.Dots {
  height: 0.8em;
  width: 0.8em;
  border-radius: 50%;
  background-color: transparent;
  outline: 0.18em solid var(--colorB);
  cursor: pointer;
}
.activeDots {
  background-color: var(--colorE);
}

@media screen and (max-width: 860px) {
  .leftArrow,
  .rightArrow {
    display: none;
  }
}
