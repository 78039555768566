.container {
  width: 100%;
  max-width: var(--layoutWidth);
  padding: 2em 0em;
}
.header {
  text-transform: capitalize;
  color: var(--colorA);
  width: 100%;
  text-align: center;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
}
.header::before {
  content: "";
  height: 0.08em;
  background: linear-gradient(to right, var(--colorA), transparent);
  width: 50%;
  left: 0px;
  margin-right: 1em;
}
.header::after {
  content: "";
  height: 0.08em;
  background: linear-gradient(to left, var(--colorA), transparent);
  width: 50%;
  right: 0px;
  margin-left: 1em;
}
.ContentWarper {
  padding: 2em 0em;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
@media screen and (max-width: 860px) {
  .header {
    font-size: 1em;
  }
}
