.container {
  width: 100%;
  padding: 0.5em;
  border-radius: 0.5em;
  background-color: white;
  text-transform: capitalize;
  cursor: pointer;
}
.container * {
  cursor: pointer;
}
.container:hover {
  background-color: var(--lightDark);
}
