.container {
  position: fixed;
  bottom: 0px;
  width: 100%;
  background-color: white;
  box-shadow: 0px 0px 5px rgb(221, 221, 221);
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  z-index: 2;
  display: none;
}
.menuItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1em 0em;
  width: 100%;
  height: 100%;
  position: relative;
  cursor: pointer;
  text-transform: capitalize;
  opacity: 0.5;
  font-size: 0.6em;
}
.menuItem.active {
  opacity: 1;
  color: var(--colorA);
  position: relative;
}
.menuItem.active::before {
  content: "";
  height: 10%;
  width: 50%;
  border-radius: 0px 0px 1em 1em;
  top: 0px;
  position: absolute;
  background-color: var(--colorA);
}

.menuItem.active .wishListCount {
  position: relative;
}
.menuItem.active .wishListCount::before {
  content: attr(data-count);
  position: absolute;
  top: -25%;
  right: -25%;
  background-color: var(--colorD);
  color: white;
  font-size: 0.8em;
  height: 1.28em;
  width: 1.28em;
  padding: 0.1em;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1em;
  font-size: 1.25em;
}

@media screen and (max-width: 1051px) {
  .container {
    display: flex;
  }
}
