.container {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-content: flex-start;
  gap: 0.5em;
  padding: 2em;
  color: white;
}
.sectionWarper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.5em;
}
.sectionWarper span {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.25em;
}
.links p,
.links {
  cursor: pointer;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.links .Icon {
  color: var(--colorA);
  transition: all 250ms;
}
.links:hover .Icon,
.links:active .Icon {
  color: var(--colorB);
}
.links:hover {
  filter: brightness(80%);
}
.links:active {
  filter: brightness(60%);
}

@media screen and (max-width: 1051px) {
  .container {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media screen and (max-width: 860px) {
  .container {
    grid-template-columns: repeat(2, 1fr);
    padding: 1em;
  }
}
