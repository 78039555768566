.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 1em;
  padding: 2em;
}
.ImageSection {
  width: 11em;
  height: 11em;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
