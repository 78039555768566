.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  min-height: 100vh;
}
.imageSection {
  height: 60vh;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
