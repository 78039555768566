.warper {
  background-color: var(--colorAAA);
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.container {
  width: 100%;
  max-width: var(--layoutWidth);
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr 5em;
}
.usefulLinksSection {
  grid-column-start: 1;
  grid-column-end: 3;
  width: 100%;
}
.contactSection {
  grid-column-start: 3;
  width: 100%;
}
.bottomSection {
  grid-row-start: 2;
  grid-column-start: 1;
  grid-column-end: 4;
  width: 100%;
}
@media screen and (max-width: 1051px) {
  .warper {
    padding-bottom: 5em;
  }
}

@media screen and (max-width: 860px) {
  .container {
    grid-template-rows: 1fr 1fr 8em;
  }
  .usefulLinksSection {
    grid-column-start: 1;
    grid-column-end: 4;
    width: 100%;
    grid-row-start: 2;
  }
  .contactSection {
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 1;
    width: 100%;
  }
  .bottomSection {
    grid-row-start: 3;
  }
}
