.warper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* box-shadow: 0px 0px 2.1px gray; */
  position: sticky;
  top: 0px;
  z-index: 2;
  background-color: white;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(21px) !important;
  -webkit-backdrop-filter: blur(21px) !important;
  border: 1px solid rgba(255, 255, 255, 0.3);
}
.container,
.nonMobileElement {
  display: flex;
  width: 100%;
  max-width: var(--layoutWidth);
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 6em;
  padding: 1em 0.5em;
}
.mobileElement {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
}
.MobileActionWarper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: unset;
  height: 100%;
  text-transform: uppercase;
}
.logoAndMenuWarper {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
}
.logoAndMenuWarper img {
  cursor: pointer;
}
.menuIconBtn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 1em;
  width: 1.25em;
  /* background-color: red; */
  position: relative;
  cursor: pointer;
  transition: ease-in-out 180ms;
  margin-right: 0.5em;
}
.menuIconBars {
  width: 100%;
  height: 0.16em;
  border-radius: 1em;
  background-color: rgb(80, 80, 80);
  position: absolute;
  transition: ease-in-out 180ms;
}
.menuIconBars:nth-child(1) {
  width: 100% !important;
  top: 0px;
}
.menuIconBars:nth-child(3) {
  width: 100% !important;
  bottom: 0px;
}
.menuIconBtn:hover .menuIconBars {
  width: 60%;
}
.menuIconBtn.active .menuIconBars:nth-child(2) {
  width: 0px;
}
.menuIconBtn.active .menuIconBars:nth-child(1) {
  display: block;
  transform: rotate(45deg);
  top: unset;
}
.menuIconBtn.active .menuIconBars:nth-child(3) {
  display: block;
  transform: rotate(-45deg);
  bottom: unset;
}
.mobileOnlyElement {
  display: none;
  flex-direction: row;
  justify-content: center;
  gap: 1em;
  align-items: center;
}

/* buy car menus */
.BuyCarSection {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-start;
}
.BuyCarTypes {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.BuyCarOptions {
  padding: 0.8em 2.5em;
  white-space: nowrap;
  width: 100%;
  margin-right: 2.5em;
}
.BuyCarOptions:hover {
  background-color: rgb(238, 238, 238);
}
.BuyCarOptions:first-child {
  background-color: rgb(225, 225, 225);
  font-size: 0.7em;
  padding: 1em 2.5em;
  padding-left: 3.6em;
  opacity: 0.6;
  text-transform: uppercase;
}

.wishListCount {
  position: relative;
}
.wishListCount::before {
  content: attr(data-count);
  position: absolute;
  top: -25%;
  right: -25%;
  background-color: var(--colorD);
  color: white;
  font-size: 0.8em;
  height: 1.28em;
  width: 1.28em;
  padding: 0.1em;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1em;
}

/* more menus */
.MoreMenus {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.MoreMenusItems {
  width: 100%;
  padding: 1em 2em;
  white-space: nowrap;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 1em;
  cursor: pointer;
}
.MoreMenusItems .icon {
  color: rgb(175, 175, 175);
}
.MoreMenusItems b {
  color: var(--colorAA);
}
.MoreMenusItems:hover,
.MoreMenusItems:hover .icon {
  background-color: rgb(238, 238, 238);
  color: var(--colorAA);
}
.contacts {
  white-space: nowrap;
  cursor: pointer;
}
.contacts * {
  cursor: pointer;
}

.sideMenuScroll * {
  cursor: pointer;
}

.serviceLinks {
  width: 100%;
  max-width: var(--layoutWidth);
  display: flex;
}
@media screen and (max-width: 1051px) {
  .serviceLinks {
    display: none;
  }
  .container {
    height: unset;
    padding: 0.5em;
    z-index: 0;
  }
  .nonMobileElement {
    display: none;
  }
  .mobileElement {
    width: 100%;
    flex-direction: column;
  }
  .mobileOnlyElement {
    display: flex;
  }
  .MobileActionWarper {
    justify-content: space-between;
    width: 100%;
    height: 2.5em;
  }
}
