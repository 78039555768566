.container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2.5em;
  position: relative;
}

.slider {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slider__track,
.slider__range,
.slider__left_value,
.slider__right_value {
  position: absolute;
}

.slider__track,
.slider__range {
  border-radius: 3px;
  height: 5px;
}

.slider__track {
  background-color: var(--colorB);
  width: 100%;
  z-index: 1;
  position: absolute;
}

.slider__range {
  background-color: var(--colorA);
  z-index: 2;
}

.slider__left_value,
.slider__right_value {
  color: var(--colorA);
  font-size: 0.8em;
  font-weight: 800;
  position: absolute;
  top: 0px;
}

.slider__left_value {
  left: 0px;
}

.slider__right_value {
  right: 0px;
}

/* Removing the default appearance */
.thumb,
.thumb::-webkit-slider-thumb {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

.thumb {
  pointer-events: none;
  position: absolute;
  height: 0em;
  width: 100%;
  outline: none;
}

.thumb_zindex_3 {
  z-index: 3;
}

.thumb_zindex_4 {
  z-index: 4;
}

.thumb_zindex_5 {
  z-index: 5;
}

.thumb::-webkit-slider-thumb {
  background-color: var(--colorA);
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px var(--colorA);
  cursor: pointer;
  height: 2em;
  width: 2em;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
  transform: translateY(-8%);
  transition: all 180ms;
}
.thumb::-moz-range-thumb {
  background-color: var(--colorA);
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px var(--colorA);
  cursor: pointer;
  height: 2em;
  width: 2em;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
  transform: translateY(0%);
  transition: all 180ms;
}

.thumb::-webkit-slider-thumb:active {
  height: 1.6em;
  width: 1.6em;
  background-color: var(--colorA);
  filter: drop-shadow(0px 0px 5px var(--colorA));
}
.thumb::-moz-range-thumb:active {
  height: 1.6em;
  width: 1.6em;
  background-color: var(--colorA);
  filter: drop-shadow(0px 0px 5px var(--colorA));
}
