.container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  min-height: 5em;
  border-radius: 0.1em;
  background-color: transparent;
  box-shadow: 8px 8px 5px rgb(238, 238, 238), -8px -8px 5px rgb(252, 252, 252);
  border-radius: 1.25em;
  border: 2px solid transparent;
  border-bottom-color: rgb(252, 252, 252);
  border-right-color: rgb(252, 252, 252);
  border-top-color: rgb(238, 238, 238);
  border-left-color: rgb(238, 238, 238);
  padding: 0.5em;
  gap: 0.5em;
}
.avatar {
  min-height: 5em;
  min-width: 5em;
  border-radius: 50%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border: 2px solid var(--colorA);
}
.info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
}
.title {
  text-transform: capitalize;
  font-size: 1.25em;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media screen and (max-width: 860px) {
  .container {
    flex-direction: column;
  }
}
