.container {
  display: flex;
  text-transform: capitalize;
  gap: 0.5em;
}
.under_text {
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.right_text {
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.avatarWarper {
  border-radius: 50%;
  min-height: 2.5em;
  min-width: 2.5em;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
