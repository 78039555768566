.container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-items: center;
  gap: 1em;
}
.cardWarper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--lightDark);
  width: 100%;
}

@media screen and (max-width: 1051px) {
  .container {
    grid-template-columns: repeat(1, 1fr);
  }
}
