.warper {
  width: 100%;
  background-color: rgb(245, 245, 245);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  max-width: var(--layoutWidth);
  min-height: 60vh;
  padding: 2.5em 1em;
  padding-bottom: 8em;
  gap: 2.5em;
}
.AccountMenuSectionWarper,
.AccountDetailViewSectionWarper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.AccountMenuSectionWarper {
  width: 30%;
}
.AccountDetailViewSectionWarper {
  width: 70%;
}

@media screen and (max-width: 860px) {
  .container {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
  }
  .AccountMenuSectionWarper,
  .AccountDetailViewSectionWarper {
    width: 100%;
  }
}
