.container {
  height: 16em;
  width: 25em;
  margin: 1em;
  border-radius: 1em;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 1em;
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
  cursor: pointer;
  transition: ease-in-out 250ms;
}
.container::before {
  content: "";
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  background: linear-gradient(to top, #0b005aa2, transparent);
}
.title,
.about {
  color: white;
  z-index: 1;
}
.container:hover {
  transform: scale(1.05);
}

@media screen and (max-width: 860px) {
  .container {
    height: 12em;
    width: 10em;
    margin: 0.4em;
    border-radius: 1em;
    padding: 0.5em;
  }
  .title {
    font-size: 0.8em;
    font-weight: 800;
  }
  .about {
    font-size: 0.6em;
  }
}
