.container {
  /* background-color: white; */
  /* box-shadow: 0px 0px 5px var(--fadeBlack); */
  width: 100%;
  border-radius: 0.5em;
  /* padding: 1em; */
  display: flex;
  flex-direction: column;
  gap: 2em;
}
