.container {
  position: fixed;
  height: 100vh;
  width: 100vw;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
.container::-webkit-scrollbar {
  display: none;
}
.contentWarper {
  border-radius: 2.5em;
  overflow: hidden;
}
.content {
  background-color: white;
  width: unset;
  padding: 2.5em;
  position: relative;
  bottom: 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  max-height: 80vh;
  overflow-x: hidden;
  overflow-y: auto;
  /* scrollbar-width: none; */
  scroll-behavior: smooth;
}

/* animation part */
.myNodeEnterActive .content {
  transform: scale(0);
}
.myNodeEnterDone .content {
  transform: scale(1);
  transition: transform 280ms;
}
.myNodeExitActive .content {
  transform: scale(1);
  transition: transform 500ms;
}
.myNodeExitDone .content {
  transform: scale(0);
}

.content::before {
  content: "";
  width: 10%;
  height: 0.25em;
  border-radius: 1em;
  background-color: gray;
  position: absolute;
  top: 1em;
  cursor: pointer;
}

@media screen and (max-width: 860px) {
  .container {
    justify-content: flex-end;
  }
  .contentWarper {
    width: 100%;
    border-radius: 2em 2em 0em 0em;
  }
  .content {
    width: 100%;
    padding: 2.5em 0em;
  }
  /* animation part */
  .myNodeEnterActive .content {
    transform: translateY(100%);
  }
  .myNodeEnterDone .content {
    transform: translateY(0);
    transition: transform 280ms;
  }
  .myNodeExitActive .content {
    transform: translateY(100%);
    transition: transform 280ms;
  }
  .myNodeExitDone .content {
    transform: translateY(0);
  }
}
