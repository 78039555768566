.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  transition: ease-in-out 150ms;
  border: 1px solid black;
  border-radius: 0.5em;
  cursor: pointer;
}
.container * {
  cursor: pointer;
}
.optionWarper {
  position: absolute;
  max-height: 0em;
  transform: translateY(-10%);
  align-self: center;
  width: 100%;
  z-index: 1;
  overflow: hidden;
  top: 100%;
  transition: ease-in-out 250ms;
  opacity: 0;
  /* border: 1px solid black; */
  border-radius: 0.5em;
  box-shadow: 0px 0px 5px rgba(80, 80, 80, 0.5);
}
.container:hover .optionWarper {
  max-height: unset;
  transition: ease-in-out 250ms;
  opacity: 1;
  transform: translateY(0%);
}
.option {
  padding: 0.8em;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: start;
  font-size: 1em;
  transition: ease-in-out 150ms;
}
.option:hover {
  filter: brightness(88%);
}
