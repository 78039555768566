.container {
  --disableCardColor: rgb(241, 241, 241);
  height: 16em;
  width: 16em;
  padding: 0.5em;
  border: 1px solid rgb(80, 80, 80);
  border-radius: 0.5em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  filter: opacity(0.5);
  cursor: pointer;
}
.container * {
  cursor: pointer;
}
.addIcon {
  color: var(--lightDark);
  font-size: 2em;
  border-radius: 50%;
  border: 5px dotted var(--disableCardColor);
  padding: 0.5em;
}
.BtnSkeleton {
  width: 80%;
  padding: 0.5em;
  border-radius: 0.5em;
  border: none;
  background-color: var(--disableCardColor);
}

@media screen and (max-width: 860px) {
  .container {
    height: 11em;
    width: 9em;
    gap: 0.5em;
    margin: 0.5em 0em;
  }
}
