.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: var(--layoutWidth);
  position: relative;
}
.container form {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
}
.inputWarper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1em;
  justify-content: center;
  width: 100%;
}
.inputWarper label {
  justify-self: center;
}
.inputWarper label input {
  padding: 1.6em;
}
.inputClassName {
  background-color: rgb(248, 248, 248);
  box-shadow: inset 2.5px 2.5px 2.5px rgb(238, 238, 238),
    inset -2.5px -2.5px 2.5px rgb(252, 252, 252);
  border-radius: 0.5em;
  border: 2px solid transparent;
  border-bottom-color: rgb(255, 255, 255);
  border-right-color: rgb(255, 255, 255);
  border-top-color: rgb(235, 235, 235);
  border-left-color: rgb(235, 235, 235);
}
.btnWarper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 2.5em;
}
.LoadingProgress {
  background-color: var(--colorB);
  height: 2.8em;
  display: flex;
  justify-content: center;
  border-radius: 1em;
  font-weight: 800;
  color: var(--colorA);
  align-items: center;
  padding: 1em;
  bottom: 0px;
  margin: 1em;
}
@media screen and (max-width: 1051px) {
  .inputWarper {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 660px) {
  .inputWarper {
    grid-template-columns: repeat(1, 85%);
  }
  .btnWarper button {
    font-size: 0.6em;
    padding: 0.5em 2em;
  }
}
