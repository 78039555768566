.container {
  width: 100%;
  height: 35em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* background-color: red; */
}
.HeroElementWarper {
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 0.25em;
  margin: 0.5em;
  padding: 0.5em;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.imageIndex {
  background-color: white;
  padding: 0.25em 0.5em;
  border-radius: 0.25em;
  bottom: 1em;
  right: 1em;
  position: absolute;
}
.leftArrow,
.rightArrow {
  visibility: hidden;
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 21%;
  padding: 0.5em;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  transition: ease-in-out 100ms;
}
.leftArrow {
  transform: translateX(-100%);
}
.rightArrow {
  transform: translateX(100%);
}
.HeroElementWarper:hover .leftArrow {
  visibility: visible;
  transform: translateX(0%);
}
.HeroElementWarper:hover .rightArrow {
  visibility: visible;
  transform: translateX(0%);
}
.leftArrow {
  left: 0px;
  border-radius: 0em 0.25em 0.25em 0em;
}
.rightArrow {
  right: 0px;
  border-radius: 0.25em 0em 0em 0.25em;
}
.leftArrow:hover,
.rightArrow:hover {
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.5);
}

.SliderImage {
  height: 5em;
  width: 8em;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0em 0.5em;
  border-radius: 0.25em;
}

.SliderImage.active {
  outline: 2px solid var(--colorA);
}

@media screen and (max-width: 1051px) {
  .leftArrow,
  .rightArrow {
    visibility: visible;
    background-color: rgba(255, 255, 255, 0.6);
  }
  .leftArrow {
    transform: translateX(0%);
  }
  .rightArrow {
    transform: translateX(0%);
  }
}
@media screen and (max-width: 560px) {
  .container {
    height: 25em;
  }
}
