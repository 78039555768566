.container {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.carTypeHolder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f2f3f5;
  border-radius: 0.5em;
  margin: 1em;
  padding: 1em 1.25em;
  cursor: pointer;
  transition: 250ms ease-in-out;
  gap: 0.5em;
}
.carTypeHolder svg {
  height: 2.1em;
  width: auto;
}
.title {
  font-size: 0.8em;
  cursor: pointer;
}
.carTypeHolder:hover {
  outline: 1px solid var(--colorA);
}
.carTypeHolder.active {
  color: var(--colorA);
  outline: 1px solid var(--colorA);
  font-weight: 600;
  transform: translateY(-5%);
}
.viewMoreBtn {
  color: var(--colorA);
  text-transform: capitalize;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: 250ms ease-in-out;
}
.viewMoreTitle {
  opacity: 0.85;
  cursor: pointer;
}
.viewMoreTitle:hover,
.viewMoreTitle:active {
  text-decoration: underline;
  opacity: 1;
}
@media screen and (max-width: 860px) {
  .carTypeHolder {
    border-radius: 0.5em;
    margin: 0.5em;
    padding: 0.8em 1em;
    gap: 0.5em;
  }
  .carTypeHolder svg {
    height: 2.1em;
  }
}
