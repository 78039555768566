.Warper {
  width: 100%;
  background-color: var(--lightDark);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.container {
  width: 100%;
  max-width: var(--layoutWidth);
  display: flex;
  flex-direction: row;
  background-color: var(--lightDark);
}
.imageSection {
  width: 60%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
}
.title {
  color: white;
  font-size: 3em;
  text-shadow: 0px 0px 25px rgba(0, 0, 0, 0.8);
  text-align: center;
  margin-top: 1em;
}
.formSection {
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2em 0px 2em 0px;
}
.formWarper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding: 1em;
  border-radius: 0.5em;
  transform: translateX(-25%);
}
.formTitle {
  display: none;
}

@media screen and (max-width: 720px) {
  .container {
    background-color: white;
  }
  .imageSection {
    display: none;
  }
  .formSection {
    width: 100%;
  }
  .formTitle {
    display: block;
  }
  .formWarper {
    padding: 0em;
    transform: translateX(0%);
    flex-direction: column;
  }
}
