.ButtonA,
.ButtonB {
  cursor: pointer;
  border: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
}
.ButtonA {
  padding: 1.2em 2.5em;
  border-radius: 0.5em;
  font-weight: bold;
  text-transform: uppercase;
  font-weight: 800;
  margin: 1em;
}
.ButtonB {
  padding: 1em 2.5em;
  border-radius: 2em;
  font-weight: bold;
  text-transform: uppercase;
  font-weight: 800;
  margin: 1em;
}
.ButtonA:hover,
.ButtonB:hover {
  filter: brightness(0.85);
}
.ButtonA:active,
.ButtonB:active {
  filter: brightness(0.8);
}
.AOne {
  background-color: #3523c9;
  color: white;
}
.ATwo {
  background-color: #b6b3ff;
  color: black;
}
.AThree {
  background-color: #776cff;
  color: white;
}
.AFour {
  background-color: #f8047e;
  color: white;
}
.AFive {
  background-color: #f7589f;
  color: white;
}

.ButtonB {
  padding: 0.5em 1em;
  border-radius: 0.5em;
  font-weight: bold;
  text-transform: uppercase;
  font-weight: 500;
  background-color: transparent;
  font-weight: 500;
  white-space: nowrap;
}
.BOne {
  border: 0.2em solid #3523c9;
  color: #3523c9;
}
.BTwo {
  border: 0.2em solid #b6b3ff;
  color: #b6b3ff;
}
.BThree {
  border: 0.2em solid #776cff;
  color: #776cff;
}
.BFour {
  border: #f8047e;
  color: #f8047e;
}
.BFive {
  border: #f7589f;
  color: #f7589f;
}

@media screen and (max-width: 1160px) {
  .ButtonA,
  .ButtonB {
    font-size: 0.8em;
    padding: 0.5em;
    margin: 0.25em;
  }
}
