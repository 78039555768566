/* menu Btn */
.closeBtn {
  position: absolute;
  top: 1em;
  right: 1em;
}
.menuIconBtn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 1.5em;
  width: 2em;
  position: relative;
  cursor: pointer;
  transition: ease-in-out 180ms;
}
.menuIconBars {
  width: 100%;
  height: 0.25em;
  border-radius: 1em;
  background-color: rgb(255, 255, 255);
  position: absolute;
  transition: ease-in-out 180ms;
}
.menuIconBars:nth-child(1) {
  width: 100% !important;
  top: 0px;
}
.menuIconBars:nth-child(3) {
  width: 100% !important;
  bottom: 0px;
}
.menuIconBtn:hover .menuIconBars {
  width: 60%;
}
.menuIconBtn.active .menuIconBars:nth-child(2) {
  width: 0px;
}
.menuIconBtn.active .menuIconBars:nth-child(1) {
  display: block;
  transform: rotate(45deg);
  top: unset;
}
.menuIconBtn.active .menuIconBars:nth-child(3) {
  display: block;
  transform: rotate(-45deg);
  bottom: unset;
}

/* side menu bar */
.SideMenu {
  display: none;
  min-height: 100vh;
  width: 100%;
  position: fixed;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 5;
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
}
.myNodeEnterActive .container {
  transform: translateX(-100%);
}
.myNodeEnterDone .container {
  transform: translateX(0);
  transition: transform 300ms;
}
.myNodeExitActive .container {
  transform: translateX(-100%);
  transition: transform 300ms;
}
.myNodeExitDone .container {
  transform: translateX(0);
}
.container {
  width: 80%;
  max-width: 25em;
  background-color: white;
}
.header {
  width: 100%;
  background-color: var(--colorAA);
  color: white;
  font-size: 0.65em;
  padding: 2.1em 1em;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5em;
}
.menusSection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.menusItem {
  width: 100%;
  padding: 1em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  text-transform: capitalize;
  gap: 1em;
  cursor: pointer;
}
.menusSection .menusItem {
  border-bottom: 1px solid rgb(225, 225, 225);
}
.menusSection .menusItem:nth-last-child(1) {
  border: none;
}
.menuItemIcon {
  color: rgb(160, 160, 160);
}
.menusItemTitle {
  color: var(--colorAA);
}

@media screen and (max-width: 1051px) {
  /* side menu bar */
  .SideMenu {
    display: flex;
  }
}
