.layout {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0em 2em;
}
.container {
  width: 100%;
  max-width: var(--layoutWidth);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  padding: 1em;
  position: relative;
  overflow-x: hidden;
}
.titleWarper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.CardWarper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: none;
  scroll-behavior: smooth;
  gap: 1.4em;
  width: 100%;
  max-width: var(--layoutWidth);
  padding: 0.5em;
}
.CardWarper::-webkit-scrollbar {
  display: none;
}
.leftArrow,
.rightArrow {
  visibility: hidden;
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 33%;
  padding: 0.5em;
  background-color: white;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  transition: ease-in-out 100ms;
}
.leftArrow {
  transform: translateX(-100%);
}
.rightArrow {
  transform: translateX(100%);
}
.CardWarper:hover .leftArrow {
  visibility: visible;
  transform: translateX(0%);
}
.CardWarper:hover .rightArrow {
  visibility: visible;
  transform: translateX(0%);
}
.leftArrow {
  left: 0px;
  border-radius: 0em 0.25em 0.25em 0em;
}
.rightArrow {
  right: 0px;
  border-radius: 0.25em 0em 0em 0.25em;
}
.leftArrow:hover,
.rightArrow:hover {
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.5);
}
@media screen and (max-width: 860px) {
  .leftArrow,
  .rightArrow {
    display: none;
  }
}
