.warper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1em;
}
.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  /* height: 80vh; */
  padding: 1em;
  border-radius: 1em;
  box-shadow: inset 8px 8px 5px rgb(238, 238, 238),
    inset -8px -8px 5px rgb(252, 252, 252);
  border-top-color: rgb(252, 252, 252);
  border-left-color: rgb(252, 252, 252);
  border-bottom-color: rgb(238, 238, 238);
  border-right-color: rgb(238, 238, 238);
}
