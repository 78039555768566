/* Custom File Input  */
.customFileInput_A_wrap {
  position: relative;
  display: flex;
  flex-direction: column !important;
  align-items: center !important;
  padding: 0.25em !important;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  padding: 0.5em;
}
.customFileInput_A_span {
  padding: 1em;
  cursor: pointer;
  border-radius: 0.5em;
  width: 100%;
  height: 11em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1em;
  color: black;
  font-weight: 600;
  text-transform: capitalize;
}
.customFileInput_A_input {
  position: absolute;
  z-index: -1;
}
.inputWarper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: rgb(248, 248, 248);
  padding: 0.1em 0.5em;
  gap: 1em;
  border-radius: 0.25em;
}
.wrongInput {
  outline-color: rgb(255, 50, 50);
  outline: 2.5px solid rgb(255, 80, 80) !important;
  background-color: rgba(255, 50, 50, 0.1);
}

.input {
  background-color: transparent;
  border: none;
  height: 100%;
  width: 100%;
  height: 2.5em;
  outline: none;
}
.actionWarper {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  height: 100%;
  width: 100%;
}
.clearBtn {
  color: var(--colorD);
}
