.lable {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  color: rgb(80, 80, 80);
  margin: 1em 0em;
  position: relative;
}
.name {
  text-transform: capitalize;
  margin-bottom: 0.5em;
  font-weight: 500;
  font-size: 1em;
}
.textareaWarper,
.inputWarper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: rgb(245, 245, 245);
  padding: 0.1em 0.5em;
  gap: 1em;
  border-radius: 0.25em;
  transition: 80ms ease-in-out;
  box-shadow: 0px 1px 1px rgb(180, 180, 180);
}
.textareaWarper:focus-within,
.inputWarper:focus-within {
  outline: 2.5px solid var(--colorC);
}
.input {
  background-color: transparent;
  border: none;
  height: 100%;
  width: 100%;
  height: 2.5em;
  outline: none;
}
.textareaWarper {
  align-items: flex-start;
}
.textarea {
  background-color: transparent;
  border: none;
  width: 100%;
  outline: none;
  font-family: "Nunito", sans-serif;
}
.WrongInput {
  outline-color: rgb(255, 50, 50);
  outline: 2.5px solid rgb(255, 80, 80) !important;
  background-color: rgba(255, 50, 50, 0.1);
}
/* Custom File Input  */
.customFileInput_A_wrap {
  position: relative;
  display: flex;
  flex-direction: column !important;
  align-items: center !important;
  padding: 0.25em !important;
}
.customFileInput_A_wrap img {
  width: 100%;
  max-width: 15em;
  height: auto;
  border-radius: 0.5em;
  margin: 0.5em;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
}
.customFileInput_A_span {
  padding: 1em;
  cursor: pointer;
  border-radius: 0.5em;
  background-color: var(--colorB);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1em;
  color: black;
  font-weight: 600;
  text-transform: capitalize;
}
.customFileInput_A_input {
  position: absolute;
  z-index: -1;
}

.errorMessageWarper {
  position: absolute;
  bottom: 0px;
  transform: translateY(110%);
  text-transform: capitalize;
}
