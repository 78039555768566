.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2em;
}
.section {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1em;
}
.section .title {
  /* text-decoration: underline; */
  position: relative;
  padding-left: 0.5em;
}
.section .title::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 0.25em;
  left: 0px;
  border-radius: 0.25em;
  background-color: var(--colorA);
}
.itemTitle {
  text-transform: capitalize;
}
.items {
  justify-self: start;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5em;
}
.items * {
  margin: 0px;
  padding: 0px;
  word-break: break-all;
  text-transform: capitalize;
}

.itemsWarper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-content: start;
  justify-items: center;
  width: 100%;
  gap: 2em;
}
@media screen and (max-width: 1151px) {
  .itemsWarper {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 500px) {
  .itemsWarper {
    grid-template-columns: repeat(1, 1fr);
  }
  .section {
    font-size: 0.8em;
    word-break: break-all;
  }
}
