.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5em;
  border: 1px solid var(--colorA);
  border-radius: 0.5em;
  padding: 1em 0.5em 0.5em 1em;
  width: 100%;
  margin: 1em;
  align-self: center;
  position: relative;
}
.cardBatchNumber {
  position: absolute;
  top: 2.5px;
  left: 2.5px;
  background-color: var(--colorE);
  border-radius: 2.5px;
  height: 1.25em;
  min-width: 1.25em;
  padding: 0.25em;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 0.8em;
  /* padding: 100% 100% 0px 0px; */
}
