.DetailsPanel {
  width: 32%;
  padding: 1em;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5em;
  border: 1px solid var(--lightDark);
  border-radius: 0.25em;
  text-transform: capitalize;
}
.DetailsPanel .title {
  color: var(--colorA);
}
.detailBookingInfo {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-self: center;
  width: 100%;
  gap: 0.5em;
  margin: 0.5em;
}
@media screen and (max-width: 860px) {
  .DetailsPanel {
    width: 100%;
  }
}
