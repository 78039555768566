.container {
  background-color: rgb(245, 245, 245);
  position: fixed;
  height: 100%;
  width: 100%;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Two {
  height: 15em;
  width: 15em;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: inset -5px -5px 8px rgb(200, 200, 200),
    inset 5px 5px 8px rgb(250, 250, 250), 5px 5px 8px rgb(200, 200, 200),
    -5px -5px 8px rgb(250, 250, 250);
  position: relative;
}
.Two::after {
  position: absolute;
  content: "";
  height: 100%;
  border-radius: 50%;
  width: 100%;
  border: 2px solid blue;
  border-top-color: transparent;
  border-bottom-color: transparent;
  animation: rotate linear infinite 1000ms;
}
.One {
  height: 8em;
  width: 8em;
  border-radius: 50%;
  box-shadow: inset 5px 5px 11px rgb(200, 200, 200),
    inset -5px -5px 11px rgb(250, 250, 250);
  border: 1em solid rgb(250, 250, 250);
  background-color: transparent;
  position: relative;
}
.One::after {
  position: absolute;
  content: "";
  height: 100%;
  border-radius: 50%;
  width: 100%;
  border: 2px solid red;
  border-top-color: transparent;
  border-bottom-color: transparent;
  animation: rotate linear reverse infinite 2000ms;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
