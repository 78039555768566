.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1em 2em;
  width: 100%;
  border-radius: 0.25em;
  gap: 1em;
  transition: ease-in-out 250ms;
  overflow: hidden;
}
.container.Collapse {
  border: 1px solid var(--fadeBlack);
}
.container.Expand {
  border: 1px solid transparent;
  background-color: var(--lightPrime);
}
.titleWarper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-transform: capitalize;
  font-size: 1.25em;
  font-weight: 400;
}
.Contents {
  text-align: start;
}

/* animation part */
.myNodeEnterActive {
  transform: translateY(100%);
}
.myNodeEnterDone {
  transform: translateY(0%);
  transition: ease-in-out 250ms;
}
.myNodeExitActive {
  transform: translateY(0%);
  transition: ease-in-out 250ms;
}
.myNodeExitDone {
  transform: translateY(100%);
}

@media screen and (max-width: 860px) {
  .container {
    font-size: 0.8em;
  }
}
