.container {
  border-radius: 0.5em;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.header {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  background-color: var(--colorC);
  border-radius: 0.5em;
  color: white;
  font-weight: 600;
  /* position: sticky; */
  top: 10em;
  width: 100%;
  z-index: 1;
}
.tabes {
  width: 100%;
  text-align: center;
  background-color: none;
  border: none;
  padding: 1em;
  cursor: pointer;
  transition: all 250ms;
  text-transform: uppercase;
  border-radius: 0.5em;
  white-space: nowrap;
}
.tabes:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.content {
  border-top: none;
  display: flex;
  flex-direction: column;
}
