.container {
  height: 100%;
}
.container form {
  font-weight: 800;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.title {
  color: var(--colorC);
}
.inputClassName {
  background-color: var(--lightPrime);
}
