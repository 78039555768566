.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5em;
  width: 100%;
  max-width: 28em;
  gap: 2.8em;
}
.menuItem {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.1em;
  background-color: transparent;
  box-shadow: 11px 11px 11px rgb(232, 232, 232),
    -11px -11px 11px rgb(255, 255, 255);
  border-radius: 1.25em;
  border: 2px solid transparent;
  border-bottom-color: rgb(255, 255, 255);
  border-right-color: rgb(255, 255, 255);
  border-top-color: rgb(232, 232, 232);
  border-left-color: rgb(232, 232, 232);
  padding: 1em 1em;
  cursor: pointer;
  columns: var(--colorAA);
  font-weight: 500;
}
.menuItem.active {
  border-color: var(--colorA) !important;
}
.menuItem:hover,
.menuItem:active {
  box-shadow: 11px 11px 11px rgb(232, 232, 232),
    -11px -11px 11px rgb(255, 255, 255), inset 8px 8px 8px rgb(235, 235, 235),
    inset -8px -8px 11px rgb(255, 255, 255);
  border-top-color: rgb(252, 252, 252);
  border-left-color: rgb(252, 252, 252);
  border-bottom-color: rgb(238, 238, 238);
  border-right-color: rgb(238, 238, 238);
}
.Icon {
  color: var(--colorA);
  transition: all 250ms;
}
.Icon.active {
  color: var(--colorA);
  transform: rotate(-90deg);
}
