.Warper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  max-height: 2.5em;
  width: 100%;
  margin: 0.5em;
}
.container {
  border-radius: 0.25em;
  border: none;
  background-color: rgb(245, 245, 245);
  padding: 0px 1em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  height: 100%;
  width: 100%;
}
.container .input,
.container .icon {
  color: rgb(121, 121, 121);
}
.container .input {
  background-color: transparent;
  border: none;
  width: 80%;
  outline: none;
}
.input:focus ~ .icon,
.input:focus {
  color: black;
}
.SearchResultsWarper,
.SearchResultsWarperTwo {
  width: 100%;
  max-height: 60vh;
  background-color: white;
  position: absolute;
  top: 2.5em;
  border-radius: 0.5em;
  z-index: 1;
  box-shadow: 0px 0px 5px var(--lightDark);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}
.SearchResultsWarperTwo {
  display: none;
  position: fixed;
  top: 1em;
}
@media screen and (max-width: 1051px) {
  .Warper {
    width: 100%;
    height: 2.5em;
  }
  .SearchResultsWarper {
    display: none;
  }
  .SearchResultsWarperTwo {
    width: 98%;
    top: 7em;
    display: flex;
  }
}
