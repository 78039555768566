.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}
.header {
  width: 100%;
  padding: 0.5em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.shorting {
  background-color: white;
  padding: 0.8em;
  /* border-radius: 0.25em; */
  /* border: 1px solid var(--colorA); */
  width: 14em;
  font-size: 1em;
}
.ContentSection {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}
.FilterWarper {
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 98vh;
  overflow: hidden;
  overflow-y: auto;
  position: sticky;
  top: 1vh;
  scrollbar-width: none;
  border-radius: 0.5em;
  border: 1px solid var(--lightDark);
  background-color: white;
}
.FilterWarper::-webkit-scrollbar {
  display: none;
}
.ItemWarper {
  width: 75%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.filterBtn {
  position: fixed;
  padding: 0.5em;
  border-radius: 50%;
  height: 2.8em;
  width: 2.8em;
  background-color: var(--colorB);
  bottom: 5em;
  right: 1em;
  display: none;
  border: none;
}

@media screen and (max-width: 860px) {
  .ItemWarper {
    justify-content: center;
    width: 100%;
  }
  .FilterWarper {
    width: 80%;
  }
  .filterBtn {
    display: block;
  }
}
