.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  padding: 1em;
}
.headerSection {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1em 0em;
  text-transform: capitalize;
}
.headerSection button {
  padding: 0.25em 0.75em;
  background-color: transparent;
  border: 2px solid var(--colorC);
  color: var(--colorC);
  border-radius: 0.25em;
  text-transform: capitalize;
}
.items {
  padding: 1em 0em;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 1em;
}
