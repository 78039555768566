.container {
  width: 100%;
  padding: 1em;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.progress {
  height: 0.5em;
  background-color: var(--colorA);
  border-radius: 1em;
  width: 50%;
}
.btnWarper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
