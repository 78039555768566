@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap");
* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
  cursor: auto;
  text-decoration: none;
  scroll-behavior: smooth;
  scrollbar-color: var(--colorC) var(--colorB);
  scrollbar-width: thin;
}
::-webkit-scrollbar {
  width: 0.5em;
}
::-webkit-scrollbar-thumb {
  background: var(--colorC);
}
::-webkit-scrollbar-track {
  background: var(--colorB);
}
a {
  color: unset;
}
:root {
  --colorA: #3523c9;
  --colorAA: #0d006e;
  --colorAAA: #090050;
  --colorB: #b6b3ff;
  --colorC: #776cff;
  --colorD: #f8047e;
  --colorE: #f7589f;
  --lightPrime: #f0f0ff;
  --lightDark: rgb(221, 221, 221);
  --fadeBlack: rgb(160, 160, 160);
  --layoutWidth: 1400px;
}

/* icon sizing */
.material-icons,
.material-icons-round {
  cursor: pointer;
}
.material-icons.md-18 {
  font-size: 18px;
}
.material-icons.md-24 {
  font-size: 24px;
}
.material-icons.md-36 {
  font-size: 36px;
}
.material-icons.md-48 {
  font-size: 48px;
}
.material-icons-round.md-18 {
  font-size: 18px;
}
.material-icons-round.md-24 {
  font-size: 24px;
}
.material-icons-round.md-36 {
  font-size: 36px;
}
.material-icons-round.md-48 {
  font-size: 48px;
}
.material-icons-outlined.md-18 {
  font-size: 18px;
}
.material-icons-outlined.md-24 {
  font-size: 24px;
}
.material-icons-outlined.md-36 {
  font-size: 36px;
}
.material-icons-outlined.md-48 {
  font-size: 48px;
}
/* icon color */
.material-icons.md-light {
  color: rgba(255, 255, 255, 1);
}
.material-icons-round.md-light {
  color: rgba(255, 255, 255, 1);
}
.material-icons-outlined.md-light {
  color: rgba(255, 255, 255, 1);
}
.material-icons.md-dark {
  color: black;
}
.material-icons-round.md-dark {
  color: black;
}
.material-icons-outlined.md-dark {
  color: black;
}
.material-icons.md-dark-light {
  color: rgb(50, 50, 50);
}
.material-icons-round.md-dark-light {
  color: rgb(50, 50, 50);
}
.material-icons-outlined.md-dark-light {
  color: rgb(50, 50, 50);
}
.material-icons.md-red {
  color: rgb(255, 80, 80);
}
.material-icons-round.md-red {
  color: rgb(255, 80, 80);
}
.material-icons-outlined.md-red {
  color: rgb(255, 80, 80);
}
.material-icons.md-blue {
  color: var(--colorA);
}
.material-icons-round.md-blue {
  color: var(--colorA);
}
.material-icons-outlined.md-blue {
  color: var(--colorA);
}

[data-fade] {
  opacity: 0.6;
}
[data-curser]:hover {
  opacity: 0.8;
  cursor: pointer;
}

body {
  margin: 0;
  font-family: "Nunito", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  width: 100%;
  position: relative;
}
#root {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* custom loader */
/* loaderOne */
.loaderOneWhite {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  gap: 0.5em;
}
.loaderOneWhite::before {
  content: "";
  min-height: 1em;
  min-width: 1em;
  max-height: 16em;
  max-width: 16em;
  background-color: transparent;
  border: 2.5px solid white;
  border-top-color: transparent;
  border-bottom-color: transparent;
  border-radius: 50%;
  animation: rotate cubic-bezier(0.68, -0.55, 0.27, 1.55) infinite 1s;
}
/* loaderOne */
.loaderOneBlue {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--colorA);
  gap: 0.5em;
}
.loaderOneBlue::before {
  content: "";
  min-height: 1em;
  min-width: 1em;
  max-height: 16em;
  max-width: 16em;
  background-color: transparent;
  border: 2.5px solid var(--colorA);
  border-top-color: transparent;
  border-bottom-color: transparent;
  border-radius: 50%;
  animation: rotate cubic-bezier(0.68, -0.55, 0.27, 1.55) infinite 1s;
}
button[disabled] {
  /* opacity: 0.8; */
  filter: grayscale(0.5);
}
button[disabled],
button[disabled]:hover,
button[disabled]:active {
  cursor: not-allowed;
  filter: grayscale(0.5);
}

[disabled] {
  cursor: not-allowed;
}

/* error message */
.EMS {
  color: red;
  font-size: 0.8em;
  display: flex;
}
.EMS::before {
  content: "*";
}
/* terms and condition */
.TMC::after {
  content: " *";
  color: red;
  /* font-size: 0.8em; */
}

/* cursor pointer all */
.CURALL,
.CURALL * {
  cursor: pointer;
}
/* text Capitalized */
.TCP {
  text-transform: capitalize;
}
/* cursor pointer */
.CP,
.CP * {
  cursor: pointer;
}

/* flex Row center and space Evenly */
.FRSA {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}
/* flex vertical center and space between */
.FRSB {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
/* flex column center and Gap 0.5em*/
.FCGP05 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5em;
  width: 100%;
}
/*flex vertical column Center */
.FCVC {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}
/* padding 0.5em */
.PD05 {
  padding: 0.5em;
}
/* style title under line */
.STUL {
  position: relative;
  padding: 0.25em 0em;
  margin: 0.5em 0em;
}
.STUL::after {
  content: "";
  position: absolute;
  height: 0.1em;
  border-radius: 1em;
  width: 80%;
  bottom: 0;
  left: 0;
  background-color: var(--colorA);
}

.OF_Y {
  overflow: hidden;
  overflow-y: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* For Hide Arrows From Input Number  */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* scroll Reveal positions*/
.reveal {
  opacity: 0;
  transition: all 600ms ease;
  transform: scale(90%);
}
.reveal-active {
  opacity: 1;
  transform: scale(100%);
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.popupConform {
  padding: 1em;
  border-radius: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1em;
  text-align: center;
}

/* skeleton animation */
.animateSkeleton {
  position: relative;
  /* animation: opacityy infinite 1200ms ease-in ; */
}
.animateSkeleton::after {
  height: 100%;
  content: "";
  width: 25%;
  top: 0;
  position: absolute;
  z-index: 1;
  animation: left-to-right-loading infinite 1200ms 1000ms
    cubic-bezier(0, 0.63, 0.93, 0.01);
}

/* Box Container */
.boxContainer {
  max-width: 420px;
  border: 1px solid var(--colorC);
  margin: 1em auto;
  padding: 0.5em;
  align-self: center;
  justify-self: center;
  border-radius: 0.25em;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1em;
}
.boxContainer .boxContainerTitle {
  color: var(--colorA);
}
.imageContainer {
  width: 100%;
  height: 100%;
  align-self: center;
  justify-self: center;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

@keyframes left-to-right-loading {
  0% {
    left: -30%;
    background: linear-gradient(
      to right,
      rgba(0, 0, 0, 0),
      rgba(121, 121, 121, 0.24),
      rgba(0, 0, 0, 0)
    );
  }
  100% {
    left: 130%;
    background: linear-gradient(
      to right,
      rgba(0, 0, 0, 0),
      rgba(121, 121, 121, 0.24),
      rgba(0, 0, 0, 0)
    );
  }
}
